import React from 'react';
import { Section } from '../../common';
import { CommunityNewsfeed } from '../../index';
import OnboardingDashboard from './onboardingDashboard';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, RolesType } from '../../../resources/roles';
import { Me } from '../../../@types/Entity/Me';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';
import classNames from 'classnames';
import { COMMUNITY_STYLE } from '../../community/communityNewsfeed';
import CertificateTemplate from '@app/asset/images/school_certificate/certificate_template.png';
import { LinkStyled } from '../../../global/link/link.styled';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Tooltip from '../../../global/tooltip/tooltip';
import { downloadSchoolCertificate } from '../../../service/api/schoolApi';
import GT_School_EBE_Badge from '@app/asset/images/GT_School_EBE_Badge.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { MoreInfo } from '../../../global/tooltip/common';
import useModal from '../../../hooks/useModal';
import BadgeModal from '../modals/badgeModal';
import posthog from 'posthog-js';

const SchoolDashboard = ({
  data,
  name,
  email,
  onboarding = false,
  role,
  registrationLink,
  me,
  finishedOnboarding = false,
}: {
  data: any;
  name: string;
  email: string;
  onboarding?: boolean;
  role?: RolesType;
  registrationLink?: string;
  me: Me;
  finishedOnboarding?: boolean;
}) => {
  const [badgeModal, toggleBadgeModal] = useModal((props: any) => <BadgeModal {...props} />, {});

  return (
    <>
      {badgeModal}
      {onboarding ? (
        <>
          <OnboardingDashboard
            link={role === ROLE_SCHOOL_COORDINATOR ? registrationLink : ''}
            me={me}
            type={
              role === ROLE_SCHOOL_COORDINATOR
                ? 'school'
                : role === ROLE_ASSISTANT_GT_COORDINATOR
                  ? 'teacher'
                  : undefined
            }
          />
        </>
      ) : (
        <>
          <div className={'block-with-sub-container mb-5'}>
            <MeetingsOverview me={me} finishedOnboarding={finishedOnboarding}/>
          </div>
          <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-8 field-mb">
            <Section
              containerClassName={'rounded-md'}
              headline={'School Profile'}
              more={
                <MoreInfo
                  content={
                    <>
                      <p>Download your personalised school certificate!</p>
                      <p>
                        You can also add a Great Teaching logo to your website, simply email the instructions to your
                        developer.
                      </p>
                    </>
                  }
                />
              }
              className={'w-full flex flex-col rounded-md'}
              size={null}
              flex
            >
              <div
                style={COMMUNITY_STYLE}
                className={`overflow-auto rounded-md ${classNames({ 'flex flex-col': false })}`}
              >
                <div className="p-2 flex w-full h-full">
                  <div className="mx-auto my-auto w-full">
                    <div className="rounded-md border border-2 p-4 w-full mb-2 grid grid-cols-8 gap-2">
                      <div className="col-span-2 flex">
                        <img
                          src={CertificateTemplate}
                          alt="certificate_template"
                          style={{ maxHeight: '60px' }}
                          className={'mx-auto my-auto'}
                        />
                      </div>
                      <div className="col-span-4 w-full h-full flex">
                        <div className="my-auto w-full mx-auto">
                          <div className={'w-full mb-4'}>
                            {me.organisation} {new Date().getFullYear()}
                          </div>
                          <div className={'w-full mt-4 text-sm'}>GTT SCHOOL CERTIFICATE</div>
                        </div>
                      </div>
                      <div className="w-full col-span-2 flex">
                        <div className={'mx-auto'}>
                          <Tooltip content={'Download certificate'}>
                            <LinkStyled
                              className={'col-span-2'}
                              onClick={() => {
                                posthog.capture('download_certificate', { user: me.id });
                                downloadSchoolCertificate();
                              }}
                              target={'_blank'}
                              id={'download_certificate'}
                            >
                              <div
                                className="mx-auto w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                <Icon elementSize={40} icon={GLOBAL_ICONS['downloadFile']}/>
                              </div>
                            </LinkStyled>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-md border border-2 p-4 w-full mb-4 grid grid-cols-8 gap-2">
                      <div className="col-span-2 flex">
                        <img
                          src={GT_School_EBE_Badge}
                          alt="gtt_ebe_school_badge"
                          style={{ maxHeight: '55px' }}
                          className={'mx-auto my-auto cursor-pointer'}
                          onClick={() => toggleBadgeModal(true)}
                        />
                      </div>
                      <div className="col-span-4 w-full h-full flex">
                        <div className="my-auto w-full">
                          <div className={'w-full mb-4'}>Great Teaching School Logo</div>
                          <div className={'w-full text-sm mt-auto'}>EMAIL/COPY INSTRUCTIONS</div>
                        </div>
                      </div>
                      <div className="flex col-span-2">
                        <div className="mx-auto flex gap-1">
                          <Tooltip content={'Email your developer'}>
                            <LinkStyled
                              href={`mailto:?subject=Your%20Great%20Teaching%20Toolkit%20logo&body=Hello%2C%0A%0AAs%20a%20Great%20Teaching%20School%2C%20we%E2%80%99d%20like%20to%20display%20our%20Great%20Teaching%20School%20logo%20on%20our%20website.%20Evidence%20Based%20Education%2C%20the%20creators%20of%20the%20Great%20Teaching%20Toolkit%2C%20have%20written%20some%20code%20to%20help%20install%20the%20logo.%20Please%20could%20you%20follow%20the%20steps%20below%3A%0A%0A1.%20Copy%20this%20snippet%20code%3A%0A%0A%3Ca%20href%3D%22https%3A%2F%2Fevidencebased.education%2Fgreat-teaching-toolkit-cpd%2F%22%20target%3D%22_blank%22%20rel%3D%22noopener%22%3E%0A%3Cimg%20src%3D%22https%3A%2F%2F2366135.fs1.hubspotusercontent-na1.net%2Fhubfs%2F2366135%2FLogos%2FGreat%2520Teaching%2520School%2520Logo%2FGTT%2520%2520Schools%2520Logo%2520Feedback%2520Landscape%2520-%2520Transparent.png%22%20alt%3D%22GTT%20EBE%20logo%22%20width%3D%22150%22%20height%3D%22100%22%20%2F%3E%0A%3C%2Fa%3E%0A%0A2.%20The%20default%20size%20of%20the%20logo%20is%20150%20x%20100%2C%20if%20you'd%20like%20to%20adjust%20it%20yourself%2C%20just%20edit%20the%20values%20width%20and%20height%20in%20the%20code%20above.%20The%20logo's%20aspect%20ratio%20is%203%3A2.%0A%0A3.%20Paste%20the%20code%20in%20your%20website's%20codebase.%0A%0A4.%20A%20version%20of%20the%20logo%20for%20websites%20with%20dark-coloured%20background%20is%20also%20available.%20In%20case%20of%20any%20troubles%20or%20questions%20-%20please%20contact%20us%20by%20sending%20an%20email%20to%3A%20support%40evidencebased.education.%0A%0AAll%20the%20Best%2C%0AEBE%20Team`}
                              target={'_blank'}
                              onClick={() => posthog.capture('logo_code_email', { user: me.id })}
                            >
                              <div
                                className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                <Icon elementSize={40} icon={GLOBAL_ICONS['envelope']}/>
                              </div>
                            </LinkStyled>
                          </Tooltip>
                          <Tooltip content={'Copy the instructions'}>
                            <LinkStyled
                              id={`copy_the_instructions`}
                              onClick={() => posthog.capture('logo_code_copy', { user: me.id })}
                            >
                              <CopyToClipboard
                                text={`Hello,

As a Great Teaching School, we’d like to display our Great Teaching School logo on our website. Evidence Based Education, the creators of the Great Teaching Toolkit, have written some code to help install the logo. Please could you follow the steps below:

1. Copy this snippet code:

<a href="https://evidencebased.education/great-teaching-toolkit-cpd/" target="_blank" rel="noopener">
<img src="https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Logos/Great%20Teaching%20School%20Logo/GTT%20%20Schools%20Logo%20Feedback%20Landscape%20-%20Transparent.png" alt="GTT EBE logo" width="150" height="100" />
</a>

2. The default size of the logo is 150 x 100, if you'd like to adjust it yourself, just edit the values width and height in the code above. The logo's aspect ratio is 3:2.

3. Paste the code in your website's codebase.

4. A version of the logo for websites with dark-coloured background is also available. In case of any troubles or questions - please contact us by sending an email to: support@evidencebased.education.

All the Best,
EBE Team
`}
                                onCopy={() => toast.success('Copied the code')}
                                options={{ format: 'text' }}
                              >
                                <div
                                  className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                  <Icon elementSize={40} icon={GLOBAL_ICONS['copy']}/>
                                </div>
                              </CopyToClipboard>
                            </LinkStyled>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
            <CommunityNewsfeed width={'lg'}/>
          </div>
        </>
      )}
    </>
  );
};

export default SchoolDashboard;
