import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { Section } from '../common';
import Tab from '../../global/tab/tab';
import DevelopmentActivitiesTable from './components/developmentActivitiesTable';
import { useQuery } from 'react-query';
import { ACADEMIC_YEARS, ME, SCHOOLS_GROUPS, USER_MANAGEMENT_LIST } from '../../service/queryKeys';
import { getAcademicYears, getMe, getSchoolGroups } from '../../service/api';
import { downloadSchoolActivitiesCsv, getSchoolActivities } from '../../service/api/schoolApi';
import { SelectOptionType } from '../../service/types';
import Selector from '../../form/select/selector';
import { DateRangePicker } from '../../form/datepicker/common';
import { Checkbox } from 'pretty-checkbox-react';
import Search from '../../form/search/search';
import { LinkStyled } from '../../global/link/link.styled';
import AggregatedMetrics from './components/aggregatedMetrics';
import Button from '../../global/button/button';
import VideoAlert from '../../guides/components/videoAlert';
import { isLoggedIn } from '../../service/auth';
import { activityTypes, filterTabs } from './resources/resources';
import { ROLE_TEACHER_FREE } from '../../resources/roles';

const DevelopmentActivities = () => {
  const [activeTabTime, setActiveTabTime] = useState<any>();
  const [activeTabUsers, setActiveTabUsers] = useState<any>();
  const [activeTabActivities, setActiveTabActivities] = useState<any>();

  const [searchValue, setSearchValue] = useState<string>('');

  const [academicYears, setAcademicYears] = useState<any>([]);
  const [term, setTerm] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [users, setUsers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [activities, setActivities] = useState<any>([]);

  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me;

  const isFree = me?.role === ROLE_TEACHER_FREE;

  const [showMetricsVideoAlert, setShowMetricsVideoAlert] = useState<boolean>(
    !me!.show_video.includes('development_activities_metrics')
  );

  const [showFilterVideoAlert, setShowFilterVideoAlert] = useState<boolean>(
    !me!.show_video.includes('development_activities_filters')
  );

  const tabs = {
    time: [
      {
        value: 1,
        label: 'Academic year & terms',
      },
      {
        value: 2,
        label: 'Date range',
      },
    ],
    users: [
      {
        value: 1,
        label: 'Users',
      },
      {
        value: 2,
        label: 'Teams',
      },
    ],
    activities: [
      {
        value: 1,
        label: 'Activities',
      },
    ],
  };

  const activityTypes = [
    { value: 'Development goal', label: 'Development goal' },
    { value: 'Continue with development cycle', label: 'Continue with development cycle' },
    { value: 'Classroom observation', label: 'Classroom observation' },
    { value: 'Action plan created', label: 'Action plan created' },
    { value: 'Video Observation', label: 'Video Feedback' },
    { value: 'Development Strategy created', label: 'Development Strategy created' },
    { value: 'GTT Course', label: 'GTT Course' },
    { value: 'Element identified', label: 'Element identified' },
    { value: 'Student survey', label: 'Student survey' },
    { value: 'Development cycle step', label: 'Development cycle step' },
    { value: 'Development cycle', label: 'Development cycle' },
    { value: 'Meeting', label: 'Meeting' },
  ];

  const teachers = useQuery(
    [
      USER_MANAGEMENT_LIST,
      '',
      searchValue,
      'active',
      1,
      academicYears,
      term,
      startDate,
      endDate,
      users,
      groups,
      activities,
    ],
    () =>
      getSchoolActivities({
        order: '',
        filter: searchValue,
        show: 'active',
        page: 1,
        academic_year_ids: academicYears.map((year: any) => year.value),
        development_period_ids: term.map((t: any) => t.value),
        start_date: startDate,
        end_date: endDate,
        users: users.map((user: any) => user.value),
        groups: groups.map((group: any) => group.value),
        activity_types: activities.map((activity: any) => activity.value),
      }),
    {
      select: (data) => data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  const academicYearsQuery = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    staleTime: Infinity,
    select: (data) => data.data,
    placeholderData: [],
  });

  const { data: schoolGroups } = useQuery([SCHOOLS_GROUPS], () => getSchoolGroups(), {
    staleTime: Infinity,
    select: (data) => data.data.data.map((group: any) => ({ label: group.name, value: group.id })),
    placeholderData: [],
  });

  const uniqueUsers = (data: any) => {
    if (data.length > 0) {
      return data.map((user: any) => ({ label: `${user.first_name} ${user.last_name}`, value: user.id }));
    }
  };

  const academicYearOptions = (data: any) => {
    return data.data.map((year: any) => ({ label: year.name, value: year.id }));
  };

  const termsOptions = (data: any, filterAcademicYear: any) => {
    const allTerms = data.data
      .map((academicYear: any) =>
        academicYear.developmentPeriods.map((period: any) => ({
          ...period,
          academic_year: academicYear.id,
        }))
      )
      .flat();

    const allTermsOptions = allTerms.map((term: any) => ({
      label: term.name,
      value: term.id,
      academic_year: term.academic_year,
    }));

    if (filterAcademicYear) {
      const allowedAcademicYears = filterAcademicYear.map((year: any) => year.value);
      return allTermsOptions.filter((term: any) => allowedAcademicYears.includes(term.academic_year));
    } else return allTermsOptions;
  };

  const dataExtractor = (activities: any) => {
    if (activities) {
      return activities
        .map((activity: any) => ({
          id: activity.id ?? '-',
          title: activity.title ?? '-',
          type: activity.type ?? '-',
          user_id: activity.user_id,
          first_name: activity.first_name ?? '-',
          last_name: activity.last_name ?? '-',
          group_name: activity?.group_name ?? '-',
          element: activity.element ?? '-',
          activity_type: activity.activity_type ?? '-',
          activity: activity.activity ?? '-',
          start_date: activity.start_date ?? '-',
          completed_date: activity.completed_at ?? '-',
        }))
        .flat();
    }
  };

  return (
    <>
      <DashboardLayout title={'Development Activities'}>
        {isFree && (
          <div>
            <VideoAlert
              setVideo={undefined}
              showHideForever={false}
              showHide={false}
              starterTemplate={isFree}
              data={{
                title: '',
                id: 'starter_template_dev_activities',
                description: (
                  <>
                    <p className={'mb-4'}>
                      This part of the platform can only be seen by a School Coordinator or Assistant Coordinator.
                    </p>
                    <p>
                      We know how important it is for schools to see that they are getting value for both time and
                      money, so in Development Activities, we provide an overview of how your colleagues are engaging
                      with the platform.
                    </p>
                  </>
                ),
              }}
            />
          </div>
        )}

        {showMetricsVideoAlert && !isFree && (
          <VideoAlert
            userId={me!.id}
            setVideo={setShowMetricsVideoAlert}
            showHideForever
            data={{
              title: 'Summary of key activities',
              id: 'development_activities_metrics',
              description: (
                <>
                  <div>
                    Welcome to your School development activities page! This top section summarises your teachers’
                    activities throughout the academic year, sorted into different activity types.
                  </div>
                  <div className={'my-1'}>
                    You can dig deeper into the details of these activities in the section below.
                  </div>
                </>
              ),
            }}
          />
        )}

        <Section className={'mb-8'}>
          <AggregatedMetrics me={me} isFree={isFree} academicYearsOptions={academicYearOptions(academicYearsQuery)} />
        </Section>

        {!isFree && (
          <>
            {showFilterVideoAlert && (
              <VideoAlert
                userId={me!.id}
                setVideo={setShowFilterVideoAlert}
                showHideForever
                data={{
                  title: 'Development activities in detail',
                  id: 'development_activities_filters',
                  description: (
                    <>
                      <div>
                        Search for specific teachers, teams or activities or filter by date, teachers, teams or
                        activities. Click export to download your selected activities.
                      </div>
                    </>
                  ),
                }}
              />
            )}
            <Section size={'md'}>
              <div className={'flex'}>
                <Button
                  className={'ml-auto'}
                  onClick={() => {
                    downloadSchoolActivitiesCsv({
                      order: '',
                      filter: searchValue,
                      show: 'active',
                      page: 1,
                      academic_year_ids: academicYears.map((year: any) => year.value),
                      development_period_ids: term.map((t: any) => t.value),
                      start_date: startDate,
                      end_date: endDate,
                      users: users.map((user: any) => user.value),
                      groups: groups.map((group: any) => group.value),
                      activity_types: activities.map((activity: any) => activity.value),
                      name: 'activities.csv',
                    });
                  }}
                >
                  Export
                </Button>
              </div>
              {/*<div className={'mt-8 lg:w-1/2 lg:mx-auto w-full mb-3 lg:mb-0'}>*/}
              {/*  <Search*/}
              {/*    id={'search_activity'}*/}
              {/*    label={'Search Activity'}*/}
              {/*    hideLabel={false}*/}
              {/*    placeholder={'Search for name, element or activity name'}*/}
              {/*    value={searchValue}*/}
              {/*    onChange={(event) => setSearchValue(event.target.value)}*/}
              {/*    onRemove={() => setSearchValue('')}*/}
              {/*  />*/}
              {/*</div>*/}
            </Section>

            <Section className={'mb-8'}>
              <div className="w-full xl:grid xl:grid-cols-2 2xl:grid-rows-1 2xl:grid-cols-3 gap-12">
                <div className={'mx-auto w-full mb-8 2xl:mb-0'}>
                  <Tab
                    items={filterTabs.time}
                    active={activeTabTime}
                    clickOnTab={(value) => {
                      activeTabTime === value ? setActiveTabTime(undefined) : setActiveTabTime(value);
                      setAcademicYears([]);
                      setTerm([]);
                      setStartDate(undefined);
                      setEndDate(undefined);
                    }}
                  >
                    <Tab.Content id={1} active={activeTabTime}>
                      <Section size={'md'}>
                        <div className="h-48 flex">
                          <div className="my-auto w-full">
                            <Selector
                              id={'selector_academic_years'}
                              placeholder={'Select Academic year'}
                              isSearchable
                              isMulti
                              options={academicYearOptions(academicYearsQuery)}
                              value={academicYears}
                              onChange={(options: any) => {
                                (options == null || options.length === 0) && setTerm([]);
                                setTerm(
                                  term.filter((term: any) =>
                                    options.map((option: any) => option.value).includes(term.academic_year)
                                  )
                                );
                                setAcademicYears(options == null ? [] : options);
                              }}
                            />
                            {academicYears.length > 0 && (
                              <div className={'mt-4'}>
                                <Selector
                                  id={'selector_terms'}
                                  placeholder={'Select Terms'}
                                  isSearchable
                                  isMulti
                                  options={termsOptions(academicYearsQuery, academicYears)}
                                  value={term}
                                  onChange={(options: any) => {
                                    setTerm(options == null ? [] : options.map((option: SelectOptionType) => option));
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Section>
                    </Tab.Content>
                    <Tab.Content id={2} active={activeTabTime}>
                      <Section size={'md'}>
                        <div className="h-48 flex">
                          <div className="mt-2 my-auto w-full">
                            <DateRangePicker
                              columnMode
                              required
                              start={startDate} /*new Date(startDate)*/
                              end={endDate} /*new Date(endDate)*/
                              onStartChange={(date) => setStartDate(date)} /*dayjs(date, 'YYYY/MM/DD').date()*/
                              onEndChange={(date) => setEndDate(date)} /*dayjs(date).format('YYYY-MM-DD'))*/
                            />
                            <LinkStyled
                              onClick={() => {
                                setStartDate(undefined);
                                setEndDate(undefined);
                              }}
                            >
                              Clear
                            </LinkStyled>
                          </div>
                        </div>
                      </Section>
                    </Tab.Content>
                  </Tab>
                </div>
                <div className={'mb-8 2xl:mb-0'}>
                  <Tab
                    items={filterTabs.users}
                    active={activeTabUsers}
                    clickOnTab={(value) => {
                      activeTabUsers === value ? setActiveTabUsers(undefined) : setActiveTabUsers(value);
                      setUsers([]);
                      setGroups([]);
                    }}
                  >
                    <Tab.Content id={1} active={activeTabUsers}>
                      <Section size={'md'}>
                        <div className="h-48 flex">
                          <div className={` my-auto w-full`}>
                            <Checkbox
                              color={'primary-o'}
                              className={'mb-4'}
                              onClick={() => {
                                users[0] === 'all' ? setUsers(['']) : setUsers(['all']);
                              }}
                            >
                              Select all users
                            </Checkbox>
                            {users[0] != 'all' && (
                              <Selector
                                id={'selector_users'}
                                placeholder={'Select users'}
                                isSearchable
                                isMulti
                                options={teachers.data?.data?.activities ? uniqueUsers(teachers.data.data.users) : []}
                                value={users}
                                onChange={(options: any) => {
                                  setUsers(options == null ? [] : options);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Section>
                    </Tab.Content>
                    <Tab.Content id={2} active={activeTabUsers}>
                      <Section size={'md'}>
                        <div className="h-48 flex">
                          <div className={`my-auto w-full`}>
                            <Checkbox
                              color={'primary-o'}
                              className={'mb-4'}
                              onClick={() => {
                                groups[0] === 'all' ? setGroups([]) : setGroups(['all']);
                              }}
                            >
                              Select all teams
                            </Checkbox>
                            {groups[0] != 'all' && (
                              <Selector
                                id={'selector_teams'}
                                placeholder={'Select teams'}
                                isSearchable
                                isMulti
                                options={schoolGroups}
                                value={groups}
                                onChange={(options: any) => {
                                  setGroups(options == null ? [] : options);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Section>
                    </Tab.Content>
                  </Tab>
                </div>
                <div>
                  <Tab
                    items={filterTabs.activities}
                    active={activeTabActivities}
                    clickOnTab={(value) => {
                      activeTabActivities === value ? setActiveTabActivities(undefined) : setActiveTabActivities(value);
                      setActivities([]);
                    }}
                  >
                    <Tab.Content id={1} active={activeTabActivities}>
                      <Section size={'md'}>
                        <div className="h-48 flex">
                          <div className="my-auto w-full">
                            <Selector
                              id={'selector_activities'}
                              placeholder={'Select activities'}
                              isMulti
                              isSearchable
                              isClearable
                              options={activityTypes}
                              value={activities}
                              onChange={(options: any) => {
                                setActivities(options == null ? [] : options);
                              }}
                            />
                          </div>
                        </div>
                      </Section>
                    </Tab.Content>
                  </Tab>
                </div>
              </div>
            </Section>
            <Section size={null}>
              {teachers.data?.data?.activities && (
                <DevelopmentActivitiesTable data={dataExtractor(teachers.data?.data?.activities)} />
              )}
            </Section>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default DevelopmentActivities;
