// GTT PROFILE FEEDBACK PAGE DATA

export const gttProfileRadarData = {
  values: [
    { '125': 82, element: '1.1' },
    { '125': 79, element: '1.3' },
    { '125': 75, element: '1.4' },
    { '125': 84, element: '2.1' },
    { '125': 76, element: '2.2' },
    { '125': 86, element: '2.3' },
    { '125': 82, element: '2.4' },
    { '125': 75, element: '3.1' },
    { '125': 76, element: '3.2' },
    { '125': 73, element: '3.3' },
    { '125': 83, element: '4.1' },
    { '125': 85, element: '4.2' },
    { '125': 53, element: '4.3' },
    { '125': 83, element: '4.4' },
    { '125': 86, element: '4.5' },
    { '125': 86, element: '4.6' },
  ],
  keys: [{ id: 125, name: 'Great Teaching Profile', dimension: null }],
  colors: ['#ee7324'],
};

export const gttProfileBarData = {
  info: [
    {
      element: '1.1 Deep and fluent knowledge',
      color: '#E63224',
      '0.10861720066237579': 82,
      'ci-0.10861720066237579': {
        u: 76,
        l: 88,
      },
      'pse-0.10861720066237579': 74,
    },
    {
      element: '1.3 Relevant curriculum tasks',
      color: '#E63224',
      '0.10861720066237579': 79,
      'ci-0.10861720066237579': {
        u: 73,
        l: 85,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '1.4 Strategies and misconceptions',
      color: '#E63224',
      '0.10861720066237579': 75,
      'ci-0.10861720066237579': {
        u: 69,
        l: 81,
      },
      'pse-0.10861720066237579': 77,
    },
    {
      element: '2.1 Teacher-student relationships',
      color: '#52b18f',
      '0.10861720066237579': 84,
      'ci-0.10861720066237579': {
        u: 78,
        l: 90,
      },
      'pse-0.10861720066237579': 78,
    },
    {
      element: '2.2 Student-student relationships',
      color: '#52b18f',
      '0.10861720066237579': 76,
      'ci-0.10861720066237579': {
        u: 70,
        l: 82,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '2.3 Learner motivation',
      color: '#52b18f',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80,
        l: 92,
      },
      'pse-0.10861720066237579': 78,
    },
    {
      element: '2.4 Climate of high expectations',
      color: '#52b18f',
      '0.10861720066237579': 82,
      'ci-0.10861720066237579': {
        u: 76,
        l: 88,
      },
      'pse-0.10861720066237579': 76,
    },
    {
      element: '3.1 Managing time and resources',
      color: '#4a82c3',
      '0.10861720066237579': 75,
      'ci-0.10861720066237579': {
        u: 69,
        l: 81,
      },
      'pse-0.10861720066237579': 70,
    },
    {
      element: '3.2 Rules and consequences',
      color: '#4a82c3',
      '0.10861720066237579': 76,
      'ci-0.10861720066237579': {
        u: 70,
        l: 82,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '3.3 Managing disruptive behaviour',
      color: '#4a82c3',
      '0.10861720066237579': 73,
      'ci-0.10861720066237579': {
        u: 67,
        l: 79,
      },
      'pse-0.10861720066237579': 79,
    },
    {
      element: '4.1 Structuring',
      color: '#594492',
      '0.10861720066237579': 83,
      'ci-0.10861720066237579': {
        u: 77,
        l: 89,
      },
      'pse-0.10861720066237579': 71.64,
    },
    {
      element: '4.2 Explaining',
      color: '#594492',
      '0.10861720066237579': 85,
      'ci-0.10861720066237579': {
        u: 79,
        l: 91,
      },
      'pse-0.10861720066237579': 71.46,
    },
    {
      element: '4.3 Questioning',
      color: '#594492',
      '0.10861720066237579': 53,
      'ci-0.10861720066237579': {
        u: 47,
        l: 59,
      },
      'pse-0.10861720066237579': 73.96,
    },
    {
      element: '4.4 Interacting',
      color: '#594492',
      '0.10861720066237579': 83,
      'ci-0.10861720066237579': {
        u: 77,
        l: 89,
      },
      'pse-0.10861720066237579': 74.91,
    },
    {
      element: '4.5 Embedding',
      color: '#594492',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80.70631058418567,
        l: 91.94621747412626,
      },
      'pse-0.10861720066237579': 71.8,
    },
    {
      element: '4.6 Activating',
      color: '#594492',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80.70631058418567,
        l: 91.94621747412626,
      },
      'pse-0.10861720066237579': 65.69,
    },
  ],
  dataKeys: [
    {
      id: '0.10861720066237579',
      name: '9 Omega (Dimension 4 - Version D - 08/02/2024)',
    },
  ],
  tooltipLabels: {
    '0.10861720066237579': {
      '4.1 Structuring': '',
      '4.2 Explaining': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      '4.3 Questioning': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      '4.4 Interacting': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      '4.5 Embedding': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      '4.6 Activating': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
    },
  },
};

export const gttProfileDimensionData = {
  questions: {
    '4.3': [
      {
        title: 'In this class, the teacher makes all students answer questions',
        direction: 1,
      },
      {
        title: 'My teacher asks students to explain more about answers they give',
        direction: 1,
      },
      {
        title: 'My teacher wants us to explain our answers — why we think what we think',
        direction: 1,
      },
      {
        title: 'My teacher gives us time to explain our ideas',
        direction: 1,
      },
      {
        title: 'When we do tests they give the teacher a pretty good idea of what we have learnt',
        direction: 1,
      },
      {
        title: 'My teacher thinks we understand even when we do not',
        direction: -1,
      },
      {
        title: 'My teacher checks to make sure we understand what he or she is teaching us',
        direction: 1,
      },
    ],
  },
  options: [
    {
      name: ' ',
      id: 7897,
    },
  ],
  data: {
    '1': [],
    '2': [],
    '3': [],
    '4': {
      '4.6': {
        '7897': {
          data: {
            'In this class, we are learning to do more by ourselves over time': 79,
            'My teacher gives us the opportunity to explore new topics independently': 53,
            'My teacher shows us how to plan our work': 74,
            'My teacher likes us to check for mistakes in our own work': 79,
            'In this class we use checklists or strategies to help us improve our work': 63,
            'When we are stuck, our teacher encourages us to work out the answer without help': 53,
            'My teacher encourages us to work independently': 42,
          },
          ci: {
            'In this class, we are learning to do more by ourselves over time': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'My teacher gives us the opportunity to explore new topics independently': {
              u: 72.96956818403937,
              l: 32.02135645840326,
            },
            'My teacher shows us how to plan our work': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'My teacher likes us to check for mistakes in our own work': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'In this class we use checklists or strategies to help us improve our work': {
              u: 80.73365537547753,
              l: 40.89368474177384,
            },
            'When we are stuck, our teacher encourages us to work out the answer without help': {
              u: 72.96956818403937,
              l: 32.02135645840326,
            },
            'My teacher encourages us to work independently': {
              u: 63.630768101273055,
              l: 23.06009951887997,
            },
          },
          pse: {
            'In this class, we are learning to do more by ourselves over time': 72.34,
            'My teacher gives us the opportunity to explore new topics independently': 63.66,
            'My teacher shows us how to plan our work': 70.4,
            'My teacher likes us to check for mistakes in our own work': 69.17,
            'In this class we use checklists or strategies to help us improve our work': 50.09,
            'When we are stuck, our teacher encourages us to work out the answer without help': 62.19,
            'My teacher encourages us to work independently': 71.95,
          },
        },
      },
      '4.5': {
        '7897': {
          data: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': 89,
            'Our teacher helps us to use learning from one subject or topic in another one': 84,
            'Our teacher helps us to remember ideas from previous lessons': 79,
            'The practice we do helps new ideas or topics make more sense': 74,
            'In class we practise and repeat certain things to help us remember them very well': 74,
            'Sometimes we return to a topic several days or several weeks later': 42,
            'Our teacher regularly asks us to practise remembering things we have learned': 79,
          },
          ci: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
            'Our teacher helps us to use learning from one subject or topic in another one': {
              u: 94.36697626576627,
              l: 62.19683634858341,
            },
            'Our teacher helps us to remember ideas from previous lessons': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'The practice we do helps new ideas or topics make more sense': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'In class we practise and repeat certain things to help us remember them very well': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'Sometimes we return to a topic several days or several weeks later': {
              u: 63.630768101273055,
              l: 23.06009951887997,
            },
            'Our teacher regularly asks us to practise remembering things we have learned': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
          },
          pse: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': 81.84,
            'Our teacher helps us to use learning from one subject or topic in another one': 68.36,
            'Our teacher helps us to remember ideas from previous lessons': 77,
            'The practice we do helps new ideas or topics make more sense': 77.75,
            'In class we practise and repeat certain things to help us remember them very well': 73.69,
            'Sometimes we return to a topic several days or several weeks later': 50.62,
            'Our teacher regularly asks us to practise remembering things we have learned': 73.37,
          },
        },
      },
      '4.4': {
        '7897': {
          data: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': 74,
            'We understand the feedback we get from our teacher': 95,
            'The feedback we get from our teacher tells us what to do to get better': 79,
            'The comments that we get on our work in this class help us understand how to improve': 89,
            'We get helpful comments to let us know what we did wrong in our work': 84,
            'My teacher regularly shows me how I can improve': 79,
          },
          ci: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'We understand the feedback we get from our teacher': {
              u: 99.14349931959168,
              l: 75.7203703170476,
            },
            'The feedback we get from our teacher tells us what to do to get better': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'The comments that we get on our work in this class help us understand how to improve': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
            'We get helpful comments to let us know what we did wrong in our work': {
              u: 94.36697626576627,
              l: 62.19683634858341,
            },
            'My teacher regularly shows me how I can improve': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
          },
          pse: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': 73.99,
            'We understand the feedback we get from our teacher': 77.33,
            'The feedback we get from our teacher tells us what to do to get better': 77.84,
            'The comments that we get on our work in this class help us understand how to improve': 76.73,
            'We get helpful comments to let us know what we did wrong in our work': 76.79,
            'My teacher regularly shows me how I can improve': 66.82,
          },
        },
      },
      '4.3': {
        '7897': {
          data: {
            'In this class, the teacher makes all students answer questions': 53,
            'My teacher checks to make sure we understand what he or she is teaching us': 57,
            'My teacher thinks we understand even when we do not': 49,
            'When we do tests they give the teacher a pretty good idea of what we have learnt': 49,
            'My teacher gives us time to explain our ideas': 54,
            'My teacher wants us to explain our answers — why we think what we think': 53,
            'My teacher asks students to explain more about answers they give': 56,
          },
          ci: {
            'In this class, the teacher makes all students answer questions': {
              u: 59,
              l: 47,
            },
            'My teacher checks to make sure we understand what he or she is teaching us': {
              u: 63,
              l: 51,
            },
            'My teacher thinks we understand even when we do not': {
              u: 55,
              l: 43,
            },
            'When we do tests they give the teacher a pretty good idea of what we have learnt': {
              u: 55,
              l: 43,
            },
            'My teacher gives us time to explain our ideas': {
              u: 60,
              l: 48,
            },
            'My teacher wants us to explain our answers — why we think what we think': {
              u: 59,
              l: 47,
            },
            'My teacher asks students to explain more about answers they give': {
              u: 62,
              l: 50,
            },
          },
          pse: {
            'In this class, the teacher makes all students answer questions': 68.37,
            'My teacher checks to make sure we understand what he or she is teaching us': 76.31,
            'My teacher thinks we understand even when we do not': 71.97,
            'When we do tests they give the teacher a pretty good idea of what we have learnt': 74.29,
            'My teacher gives us time to explain our ideas': 79.54,
            'My teacher wants us to explain our answers — why we think what we think': 79.31,
            'My teacher asks students to explain more about answers they give': 82.45,
          },
        },
      },
      '4.2': {
        '7897': {
          data: {
            'Before teaching a new topic or idea, my teacher checks that all students understand and remember the previous work that it builds on': 84,
            'When the teacher is explaining, he or she uses really good examples to help us understand': 95,
            'We enjoy listening to my teacher': 89,
            'My teacher explains difficult things clearly': 89,
            'My teacher is able to make new ideas interesting': 74,
            'We are always interested when the teacher is explaining': 58,
            'My teacher often explains too much at once for us': 84,
            'My teacher will explain something until we understand': 89,
          },
          ci: {
            'Before teaching a new topic or idea, my teacher checks that all students understand and remember the previous work that it builds on': {
              u: 94.36697626576627,
              l: 62.19683634858341,
            },
            'When the teacher is explaining, he or she uses really good examples to help us understand': {
              u: 99.14349931959168,
              l: 75.7203703170476,
            },
            'We enjoy listening to my teacher': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
            'My teacher explains difficult things clearly': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
            'My teacher is able to make new ideas interesting': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'We are always interested when the teacher is explaining': {
              u: 76.93990048112003,
              l: 36.36923189872695,
            },
            'My teacher often explains too much at once for us': {
              u: 94.36697626576627,
              l: 62.19683634858341,
            },
            'My teacher will explain something until we understand': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
          },
          pse: {
            'Before teaching a new topic or idea, my teacher checks that all students understand and remember the previous work that it builds on': 68.13,
            'When the teacher is explaining, he or she uses really good examples to help us understand': 82.19,
            'We enjoy listening to my teacher': 67.8,
            'My teacher explains difficult things clearly': 76.76,
            'My teacher is able to make new ideas interesting': 70.94,
            'We are always interested when the teacher is explaining': 58.14,
            'My teacher often explains too much at once for us': 73.41,
            'My teacher will explain something until we understand': 78.67,
          },
        },
      },
      '4.1': {
        '7897': {
          data: {
            'In this class, our work is just the right level of difficulty': 79,
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': 89,
            'After my teacher has explained a new idea we are usually ready to try an example': 74,
            'My teacher shows us how we are making progress through a topic': 58,
            'My teacher summarises the most important information at the end of the lesson': 79,
            'My teacher helps us to understand how each lesson fits into the bigger picture': 74,
            'My teacher helps us to understand why what we are learning in class is important': 84,
            'At the beginning of the lesson my teacher recaps what we have done previously': 89,
          },
          ci: {
            'In this class, our work is just the right level of difficulty': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
            'After my teacher has explained a new idea we are usually ready to try an example': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'My teacher shows us how we are making progress through a topic': {
              u: 76.93990048112003,
              l: 36.36923189872695,
            },
            'My teacher summarises the most important information at the end of the lesson': {
              u: 91.52384816620939,
              l: 56.721756710735946,
            },
            'My teacher helps us to understand how each lesson fits into the bigger picture': {
              u: 88.39922298756434,
              l: 51.52817415197662,
            },
            'My teacher helps us to understand why what we are learning in class is important': {
              u: 94.36697626576627,
              l: 62.19683634858341,
            },
            'At the beginning of the lesson my teacher recaps what we have done previously': {
              u: 96.85169406044899,
              l: 68.03032629130509,
            },
          },
          pse: {
            'In this class, our work is just the right level of difficulty': 72.24,
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': 77.99,
            'After my teacher has explained a new idea we are usually ready to try an example': 79.23,
            'My teacher shows us how we are making progress through a topic': 68.67,
            'My teacher summarises the most important information at the end of the lesson': 59.66,
            'My teacher helps us to understand how each lesson fits into the bigger picture': 69.2,
            'My teacher helps us to understand why what we are learning in class is important': 74.85,
            'At the beginning of the lesson my teacher recaps what we have done previously': 71.26,
          },
        },
      },
    },
  },
};

// DOWNLOAD FEEDBACK DIMENSION

export const downloadGttProfileDimensionData = {
  info: [
    {
      '7897': 79,
      question: ' In this class, the teacher makes all students answer questions',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 91.52384816620939,
        l: 56.721756710735946,
      },
      'pse-7897': 68.37,
    },
    {
      '7897': 79,
      question: ' My teacher asks students to explain more about answers they give',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 91.52384816620939,
        l: 56.721756710735946,
      },
      'pse-7897': 82.45,
    },
    {
      '7897': 89,
      question: ' My teacher wants us to explain our answers — why we think what we think',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 96.85169406044899,
        l: 68.03032629130509,
      },
      'pse-7897': 79.31,
    },
    {
      '7897': 84,
      question: ' My teacher gives us time to explain our ideas',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 94.36697626576627,
        l: 62.19683634858341,
      },
      'pse-7897': 79.54,
    },
    {
      '7897': 84,
      question: ' When we do tests they give the teacher a pretty good idea of what we have learnt',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 94.36697626576627,
        l: 62.19683634858341,
      },
      'pse-7897': 74.29,
    },
    {
      '7897': 89,
      question: ' My teacher thinks we understand even when we do not',
      questionDirection: -1,
      color: '#594492',
      'ci-7897': {
        u: 96.85169406044899,
        l: 68.03032629130509,
      },
      'pse-7897': 71.97,
    },
    {
      '7897': 89,
      question: ' My teacher checks to make sure we understand what he or she is teaching us',
      questionDirection: 1,
      color: '#594492',
      'ci-7897': {
        u: 96.85169406044899,
        l: 68.03032629130509,
      },
      'pse-7897': 76.31,
    },
  ],
  dataKeys: [
    {
      name: '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      id: 7897,
    },
  ],
  color: '#594492',
};

// PASSPORT PAGE DATA

export const passportRadarData = {
  values: [
    { '125': 82, element: '1.1' },
    { '125': 79, element: '1.3' },
    { '125': 75, element: '1.4' },
    { '125': 84, element: '2.1' },
    { '125': 76, element: '2.2' },
    { '125': 86, element: '2.3' },
    { '125': 82, element: '2.4' },
    { '125': 75, element: '3.1' },
    { '125': 76, element: '3.2' },
    { '125': 73, element: '3.3' },
    { '125': 83, element: '4.1' },
    { '125': 85, element: '4.2' },
    { '125': 53, element: '4.3' },
    { '125': 83, element: '4.4' },
    { '125': 86, element: '4.5' },
    { '125': 86, element: '4.6' },
  ],
  keys: [{ id: 125, name: 'Great Teaching Profile', dimension: null }],
  colors: ['#ee7324'],
};

// TEACHERS PAGE DATA

export const teachersTemplateData = {
  data: {
    users: [
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Benjamin',
        last_name: 'Davies',
        email: 'benjamin.davies@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.1 Structuring'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Emily',
        last_name: 'Clarke',
        email: 'emily.clarke@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.1 Structuring'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Ahmed',
        last_name: 'Rahman',
        email: 'ahmed.rahman@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.1 Structuring'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Catherine',
        last_name: 'Thompson',
        email: 'catherine.thompson@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.1 Structuring'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'David',
        last_name: 'Roberts',
        email: 'david.roberts@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.1 Structuring'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Rebecca',
        last_name: 'Hughes',
        email: 'rebecca.hughes@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.2 Explaining'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Jonathan',
        last_name: 'Wilson',
        email: 'jonathan.wilson@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.2 Explaining'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Sunita',
        last_name: 'Gupta',
        email: 'sunita.gupta@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.2 Explaining'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Elizabeth',
        last_name: 'Brown',
        email: 'elizabeth.brown@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.2 Explaining'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Charlotte',
        last_name: 'Taylor',
        email: 'charlotte.taylor@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.2 Explaining'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Matthew',
        last_name: 'Evans',
        email: 'matthew.evans@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.3 Questioning'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Victoria',
        last_name: 'Phillips',
        email: 'victoria.phillips@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.3 Questioning'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Daniel',
        last_name: 'Harris',
        email: 'daniel.harris@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.3 Questioning'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Aisha',
        last_name: 'Khan',
        email: 'aisha.khan@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.3 Questioning'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Jennifer',
        last_name: 'Carter',
        email: 'jennifer.carter@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.3 Questioning'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Andrew',
        last_name: 'Johnson',
        email: 'andrew.johnson@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.4 Interacting'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Sarah',
        last_name: 'King',
        email: 'sarah.king@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.4 Interacting'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Christopher',
        last_name: 'Wright',
        email: 'christopher.wright@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.4 Interacting'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Lauren',
        last_name: 'Morgan',
        email: 'lauren.morgan@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.4 Interacting'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'James',
        last_name: 'Robinson',
        email: 'james.robinson@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.4 Interacting'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Michael',
        last_name: 'White',
        email: 'michael.white@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.5 Embedding'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Mei',
        last_name: 'Wong',
        email: 'mei.wong@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.5 Embedding'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Sophie',
        last_name: 'Mitchell',
        email: 'sophie.mitchell@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.5 Embedding'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Peter',
        last_name: 'Turner',
        email: 'peter.turner@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.5 Embedding'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Natalie',
        last_name: 'Scott',
        email: 'natalie.scott@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.5 Embedding'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Stephen',
        last_name: 'Hall',
        email: 'stephen.hall@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.6 Activating'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Olivia',
        last_name: 'Davis',
        email: 'olivia.davis@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.6 Activating'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Nicholas',
        last_name: 'Clark',
        email: 'nicholas.clark@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.6 Activating'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Jamal',
        last_name: 'Patel',
        email: 'jamal.patel@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.6 Activating'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
      {
        id: 'test',
        confirmed: 'test',
        first_name: 'Hannah',
        last_name: 'Allen',
        email: 'hannah.allen@gtt.demo',
        active: true,
        great_teaching_lead: false,
        organisation: 'test',
        role: 'ROLE_TEACHER',
        bulk_subscription_id: 'test',
        upgraded: false,
        element: undefined,
        current_pathway_step: undefined,
        groups: ['Team 4.6 Activating'],
        slots: undefined,
        subscription_type: undefined,
        school_has_mentoring: undefined,
        mentor: undefined,
        development_activities: undefined,
      },
    ],
  },
  meta: {
    total: 4,
    max_page: 250,
  },
};

// Development activities

export const starterTemplateDevelopmentActivities = {
  data: {
    teachers: 30,
    school: 'School Name', //CHANGE THAT TO DYNAMIC organisation_name
    foundation_course: {
      completed: 80,
      onboarding: 100,
    },
    survey: {
      average_completed_survey: 80,
      number_survey_instances: 20,
      number_completed_survey: 6,
      number_survey_responses: 440,
    },
    teacher_courses: {
      start: 25,
      complete: 18,
      average_completed: 72,
    },
    lead_programme_courses: {
      start: 5,
      complete: 3,
      average_completed: 60,
    },
    element: {
      start_elements: 100,
      elements: ['4.1', '4.2', '4.3', '4.4', '4.5', '4.6'],
    },
    development_cycle_completed: 0,
    time_cost: {
      hours: 342,
      cost: 145692,
    },
  },
};

// VIDEO FEEDBACK DATA

export const starterTemplateVideoFeedbackData = [
  {
    id: 1639,
    name: '4.3 Questioning',
    colour: '#5d3f92',
    tags: [
      {
        id: 2434,
        name: 'Adequate thinking time is allowed to students when posing questions',
      },
      {
        id: 2435,
        name: 'The design/format of questions is appropriate for the content being taught',
      },
      {
        id: 2436,
        name: 'Questions asked prompt students to think deeply and develop understanding',
      },
      {
        id: 2437,
        name: 'Questions asked promote rich dialogue which moves learning forward',
      },
      {
        id: 2438,
        name: 'The teacher asks questions which assess if new ideas have been understood',
      },
      {
        id: 2439,
        name: 'The teacher regularly elicits question responses from all students',
      },
      {
        id: 2440,
        name: 'Students are asked to justify or explain their answers, or others’ answers',
      },
    ],
  },
];
