import React from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import dayjs from 'dayjs';
import ElementSquare from '../../../mfgtPage/components/elementSquare';
import { useQuery } from 'react-query';
import { ME } from '../../../../service/queryKeys';
import { getMe } from '../../../../service/api';

const V3InfoSection = ({ pathway }: { pathway: V3Pathway }) => {
  const data = {
    goals: pathway.goals ?? [],
    strategies: pathway.strategies ?? [],
    actions: pathway.actions ?? [],
    resources: pathway.resources ?? [],
    guidance: pathway.guidance ?? [],
  };

  const elements = [
    { label: 'Dimension 1: Understanding the content', value: '1', type: 'dimension' },
    { label: '1.1 Deep and fluent knowledge', value: '1.1', type: 'element' },
    { label: '1.2 Curriculum sequencing', value: '1.2', type: 'element' },
    { label: '1.3 Relevant curriculum tasks', value: '1.3', type: 'element' },
    { label: '1.4 Strategies and misconceptions', value: '1.4', type: 'element' },
    { label: 'Dimension 2: Creating a supportive environment', value: '2', type: 'dimension' },
    { label: '2.1 Teacher-student relationships', value: '2.1', type: 'element' },
    { label: '2.2 Student-student relationships', value: '2.2', type: 'element' },
    { label: '2.3 Learner motivation', value: '2.3', type: 'element' },
    { label: '2.4 Climate of high expectations', value: '2.4', type: 'element' },
    { label: 'Dimension 3: Maximising opportunity to learn', value: '3', type: 'dimension' },
    { label: '3.1 Managing time and resources', value: '3.1', type: 'element' },
    { label: '3.2 Rules and consequences', value: '3.2', type: 'element' },
    { label: '3.3 Managing disruptive behaviour', value: '3.3', type: 'element' },
    { label: 'Dimension 4: Activating hard thinking', value: '4', type: 'dimension' },
    { label: '4.1 Structuring', value: '4.1', type: 'element' },
    { label: '4.2 Explaining', value: '4.2', type: 'element' },
    { label: '4.3 Questioning', value: '4.3', type: 'element' },
    { label: '4.4 Interacting', value: '4.4', type: 'element' },
    { label: '4.5 Embedding', value: '4.5', type: 'element' },
    { label: '4.6 Activating', value: '4.6', type: 'element' },
  ];

  function findLabelByValue(value: string): string | undefined {
    const option = elements.find((option) => option.value === value);
    return option ? option.label : undefined;
  }

  const me = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  function formatDate(inputDate: string) {
    // const [day, month, year] = inputDate.split('.');

    const date = new Date(inputDate);
    const day = date.getUTCDate().toString();
    const month = date.getUTCMonth() + 1;

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthName = monthNames[parseInt(String(month), 10) - 1];

    let daySuffix;
    if (['1', 1, '11', 11, '21', 21].includes(day)) {
      daySuffix = 'st';
    } else if (['2', 2, '12', 12].includes(day)) {
      daySuffix = 'nd';
    } else if (['3', 3, '13', 13].includes(day)) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }

    const formattedDate = `${parseInt(String(day), 10)}${daySuffix} of ${monthName}`;

    return formattedDate;
  }

  return (
    <Section>
      <div className={'flex justify-between'}>
        <div className={''}>
          <div className={'flex items-center gap-5 mb-5'}>
            <div className={'w-8 text-center'}>
              <p>{dayjs().format('ddd')}</p>
              <p className={'text-2xl font-bold'} style={{ marginTop: '-5px' }}>
                {dayjs().format('DD')}
              </p>
            </div>
            {pathway.focus && (
              <ElementSquare value={pathway.focus.value} type={pathway.focus.type} title={pathway.focus.label} />
            )}
          </div>
          {
            /*!pathway.group ? (
            pathway.focus === null ? (
              <>No focus selected</>
            ) : pathway.focus != null &&
            data.goals.length < 1 &&
            data.strategies.length < 1 &&
            data.actions.length < 1 &&
            data.resources.length < 1 ? (
              <>Only focus</>
            ) : pathway.focus != null && data.goals.length > 0 && data.strategies.length < 1 ? (
              <>Only focus and goal, no strategy</>
            ) : pathway.focus != null &&
            data.goals.length > 0 &&
            data.strategies.length > 0 &&
            data.actions.length < 1 ? (
              <>Focus, goal, strategy but no action</>
            ) : (
              <></>
            )
          ) : */ pathway.group &&
          data.guidance !== null &&
          data.guidance!.length !== 0 &&
          data.guidance !== undefined ? (
            <div className={'bg-gray-100 p-6 my-auto shadow-md'}>
              {data.guidance && data.guidance!.length !== 0 && data.guidance.meeting_time ? (
                data.guidance.template_type === null || data.guidance.template_purpose === null ? (
                  <>
                    {' '}
                    Hi <strong>{me?.data?.first_name}</strong>, your next meeting is on the{' '}
                    {formatDate('2024-05-02T12:15:00+00:00')}.
                  </>
                ) : (
                  <>
                    Hi <strong>{me?.data?.first_name}</strong>, you&apos;re currently {data.guidance.template_type}.
                    In your next meeting on the {formatDate(data.guidance.meeting_time)} you&apos;ll{' '}
                    {data.guidance.template_purpose} with your team.
                  </>
                )
              ) : (
                <span>
          This is a place to record your goals, decisions and actions as you work on an element of Great
          Teaching. This workspace is for your own development – it&apos;s private to you.
        </span>
              )}
            </div>
          ) : (
            <span>
      This is a place to record your goals, decisions and actions as you work on an element of Great Teaching.
      This workspace is for your own development – it&apos;s private to you.
    </span>
          )
          }
          {pathway.group && (
            <div className={'mt-4'}>
              <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                {pathway.group.name}
              </span>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default V3InfoSection;
