import React, { useEffect, useState } from 'react';
import Icon from '../../../global/icon/icon';
import useModal from '../../../hooks/useModal';
import { LinkStyled } from '../../../global/link/link.styled';
import { toast } from 'react-toastify';
import Button from '../../../global/button/button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getDashboardCheckList, updateDashboardCheckList } from '../../../service/api/schoolApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DASHBOARD_CHECKLIST } from '../../../service/queryKeys';
import { checklistItem, OnboardingChecklistType } from './resources';
import { AddButton } from '../../../global/button/common';
import EventsModal from '../../events/modals/EventsModal';
import { GLOBAL_ICONS, SIZE_ICON } from '../../../resources/vars';

const OnboardingChecklist = ({
  type,
  link = '',
  setAtLeastOneValueChecked,
  me,
  thinkificLink = '',
}: OnboardingChecklistType) => {
  const queryClient = useQueryClient();

  const { data: getChecklist, isFetched } =
    type === 'school'
      ? useQuery<any>([DASHBOARD_CHECKLIST], () => getDashboardCheckList(), {
          select: (data) => data.data.data,
        })
      : { data: undefined, isFetched: false };

  const updateChecklist = useMutation(updateDashboardCheckList, {
    onSuccess: () => {
      queryClient.invalidateQueries(DASHBOARD_CHECKLIST);
    },
  });

  const findValueInChecklist = (keyword: string, value: string) => {
    return getChecklist?.find((checklistItem: any) => checklistItem.keyword === keyword)[value];
  };

  const [eventModal, toggleEventModal, setEventModalProps] = useModal((props: any) => (
    <EventsModal {...props} me={me} />
  ));

  const items: checklistItem[] | [] =
    type === 'school'
      ? [
          {
            title: 'Introduction to the GTT',
            value: (
              <div className={'h-full flex'}>
                <p className={'m-auto text-center'}>
                  Check your email for the <b>“Launching and leading the Great Teaching Toolkit”</b> guide and work your
                  way through it to learn about how to plan for how to use the GTT in your school
                </p>
              </div>
            ),
            keyword: 'intro',
            finished: findValueInChecklist('intro', 'complete'),
            id: findValueInChecklist('intro', 'id') ?? '',
          },
          {
            title: 'Send the registration link to your colleagues',
            value: (
              <>
                <div className={'flex w-full h-full'}>
                  <div className="m-auto">
                    <p className={'text-center mb-4'}>
                      Your colleagues can create their accounts using your school’s registration link. If you copy the
                      link and instructions, you can send them by email.
                    </p>
                    <div className="flex">
                      <CopyToClipboard
                        text={`<div>
                    <p>This year, our CPD plans will involve the Great Teaching Toolkit – an online platform we can use to work together to further develop our teaching practice.</p>
                    <p>Here's what you need to do to access the platform:</p>
                    <ul>
                        <li>
                        <strong>Step 1:</strong> Please click on this <a href=${link} target={'_blank'}>link</a> to create your account.</li>
                        <li><strong>Step 2:</strong> Once you have created your account, you'll be able to log in <a href="https://toolkit.greatteaching.com/login" target={'_blank'}>here</a>. We recommend saving this email for future reference.</li>
                    </ul>
                </div>`}
                        onCopy={() => {
                          toast.success('Registration instructions have been copied');
                        }}
                        options={{ format: 'text/html' }}
                      >
                        <Button id={'dashboard_copy_signup_instructions'} className={'mx-auto'} isOutline size={'sm'}>
                          Copy registration instructions
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </>
            ),
            keyword: 'registration',
            finished: findValueInChecklist('registration', 'complete'),
            id: findValueInChecklist('registration', 'id') ?? '',
          },
          {
            title: 'Make a plan',
            value: (
              <div className="h-full flex">
                <p className={'my-auto h-fit text-left'}>
                  Decide how the GTT will be used by your colleagues this term:
                  <p className={'mt-2 mb-1'}>1. How will teams be formed (e.g. by department)?</p>
                  <p>
                    2. Which element will they work on first? (will each team decide, or will you guide them to begin
                    with?)
                  </p>
                </p>
              </div>
            ),
            keyword: 'plan',
            finished: findValueInChecklist('plan', 'complete'),
            id: findValueInChecklist('plan', 'id') ?? '',
          },
          {
            title: 'Arrange a kick off',
            value: (
              <div className={'flex w-full h-full'}>
                <div className="m-auto">
                  <p className={'text-center mb-4'}>
                    Gather your colleagues together to explain the plan for the first few weeks. You can add an event
                    for this to their GTT calendars:
                  </p>
                  <div className={'flex'}>
                    <Button
                      className={'mx-auto'}
                      onClick={() => {
                        setEventModalProps({
                          title: 'Add school event',
                        });
                        toggleEventModal(true);
                      }}
                    >
                      <p className={'flex gap-3'}>
                        <Icon icon={GLOBAL_ICONS['create']} container={false} elementSize={SIZE_ICON['sm']} />
                        Add school event
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            ),
            keyword: 'kickoff',
            finished: findValueInChecklist('kickoff', 'complete'),
            id: findValueInChecklist('kickoff', 'id') ?? '',
          },
        ]
      : type === 'teacher'
      ? [
          {
            id: 'teacherStep1',
            title: 'Learn about the GTT',
            value: (
              <div className="h-full flex">
                <p className={'my-auto h-fit text-center'}>
                  Take some time to learn about the GTT by exploring section 1 of this{' '}
                  <LinkStyled href={thinkificLink} target={'_blank'}>foundation course</LinkStyled>
                </p>
              </div>
            ),
          },
          {
            id: 'teacherStep2',
            title: 'Kick-off meeting',
            value: (
              <div className="h-full flex">
                <div className={'flex-column my-auto'}>
                  <p className={'h-fit text-left w-full'}>
                    Keep an eye out for an invitation to a meeting with your colleagues who are also using the toolkit.
                  </p>
                  <p className={'h-fit text-left w-full mt-1'}>
                    If you have any doubts, don’t hesitate to ask the person in your school who is coordinating the GTT.
                  </p>
                </div>
              </div>
            ),
          },
          {
            id: 'teacherStep3',
            title: "When you're ready",
            value: (
              <div className="h-full flex">
                <p className={'my-auto h-fit text-center'}>
                  Once you’ve met with your colleagues and you have a bit of a plan for next steps, click the start
                  button to set up your first development cycle
                </p>
              </div>
            ),
          },
        ]
      : [];

  const [item, setItem] = useState<checklistItem | undefined>(items[0]);

  useEffect(() => {
    if (isFetched) {
      if (item === undefined || item?.id === '') setItem(items[0]);
      if (items.some((item) => item.finished === true)) {
        setAtLeastOneValueChecked(false);
      }
    }
    if (items.every((item) => item.finished === false)) {
      setAtLeastOneValueChecked(true);
    }
  }, [getChecklist]);

  return (
    <>
      {eventModal}
      <div className="grid grid-cols-1 grid-rows-2 xl:grid-cols-2 xl:grid-rows-1 gap-4 w-full mx-auto p-2">
        <div className={`w-full pr-2 border-2 my-2 p-3 ${type === 'teacher' ? ' /*md:w-2/3*/' : ''}`}>
          {items.map((thisItem: checklistItem, index: number) => {
            return (
              <div
                key={index}
                className={`flex my-2 cursor-pointer py-1`}
                onClick={() => {
                  setItem(thisItem);
                }}
              >
                {type === 'school' && (
                  <Icon
                    elementSize={30}
                    color={'green'}
                    className={'p-1 mr-1 my-1'}
                    onClick={() => {
                      if (thisItem.id) updateChecklist.mutateAsync(thisItem.id);
                    }}
                    icon={thisItem.finished ? 'CheckCircleFill' : 'Circle'}
                  />
                )}
                <div className={'w-2/3 grid '}>
                  <div className={`${item?.id === thisItem.id ? ' bg-gray-200' : ''} rounded-md py-1 px-3`}>
                    {type === 'school' ? (
                      <>{thisItem.title}</>
                    ) : (
                      type === 'teacher' && (
                        <p className={'mr-1 '}>
                          {index + 1}. {thisItem.title}
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {item && (
          <div className="w-full mx-auto border-2 my-2 px-2 py-1">
            <div className={'w-full h-full'}>{item.value}</div>
          </div>
        )}
      </div>
    </>
  );
};
export default OnboardingChecklist;
