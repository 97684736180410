import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { postLogin, postSendResetPasswordMail } from '../../service/api';
import { login } from '../../service/auth';
import { LOGIN_ERROR } from '../../service/errors';
import AuthLayout from '../../layout/auth';
import Button from '../../global/button/button';
import Input from '../../form/input/input';
import { LinkStyled } from '../../global/link/link.styled';
import useModal from '../../hooks/useModal';
import ResetPasswordModal from './ResetPassword/resetPasswordModal';
import { toast } from 'react-toastify';
import VideoAlert from '../../guides/components/videoAlert';

const LoginPage: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const SCHEMA = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
  });

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <ResetPasswordModal {...props} />, {
    title: 'Reset password',
    size: 'sm',
  });

  const customErrorMessage: JSX.Element = (
    <div>
      <p>There has been an error with logging you in.</p>
      <p>
        If you are trying to access your GTT account, please try to log in on{' '}
        <LinkStyled target={'_blank'} href={'https://toolkit.greatteaching.com/login'}>
          this page
        </LinkStyled>{' '}
        instead.
      </p>
      <p>
        If this is not the case, please{' '}
        <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
          contact us here
        </LinkStyled>
        .
      </p>
    </div>
  );

  return (
    <AuthLayout
      titleCentered
      title={'Login into your account'}
      description={
        <div className="w-full">
          <VideoAlert
            showHideForever={false}
            fullWidth
            showHide={false}
            centerData
            largeDescription
            data={{
              title: 'Welcome to the National Institute of Teaching login page',
              description: 'Please bookmark this page for future use',
            }}
            setVideo={undefined}
          />
        </div>
      }
    >
      {modal}
      <Formik
        initialValues={{ email: '', password: '' }}
        isInitialValid={false}
        validationSchema={SCHEMA}
        validateOnMount
        onSubmit={(values) => {
          setLoading(true);

          postLogin(values)
            .then((response) => {
              login(response.data.token);
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                setError('Invalid credentials.' === response.data.message ? LOGIN_ERROR : response.data.message);
                return;
              }
              setError('An error has occurred.');
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values, handleSubmit, handleChange, isValid, errors }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            <div className={'field-mb'}>
              <Input
                id={'email'}
                type={'email'}
                label={'Email Address'}
                placeholder={'Enter your email address'}
                required
                value={values.email}
                onChange={(e) => {
                  handleChange(e);
                  setError('');
                }}
              />
            </div>
            <div className={'field-mb'}>
              <Input
                id={'password'}
                type={'password'}
                label={'Password'}
                hintText={
                  <Button
                    asLink
                    style={{ padding: 0, background: 'transparent' }}
                    disabled={!!(values.email === '' || errors.email)}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (values.email === '' || errors.email) {
                        return;
                      }
                      postSendResetPasswordMail(values.email).catch(() => {
                        toast.error('An unexpected error has occurred, please try again');
                      });
                      setModalProps({
                        email: values.email,
                      });
                      toggleModal(true);
                    }}
                  >
                    Reset password
                  </Button>
                }
                placeholder={'Enter your password'}
                required
                value={values.password}
                error={error}
                customError={error === 'gttUserLogin' ? customErrorMessage : undefined}
                onChange={(e) => {
                  handleChange(e);
                  setError('');
                }}
              />
            </div>
            <Button className={'block w-full mt-5'} type={'submit'} disabled={!isValid || loading}>
              {loading ? 'Loading...' : 'Continue'}
            </Button>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default LoginPage;
