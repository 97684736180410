import React, { useState } from 'react';
import DashboardLayout from "../../layout/dashboard";
import InstrumentForm from "./sections/instrumentForm";
import InstrumentMonitor from "./sections/instrumentMonitor";
import { useQuery } from "react-query";
import { ACTIVE_INSTRUMENTS } from "../../service/queryKeys";
import { getActiveInstruments } from "../../service/api";
import SchoolEnvFeedback from "./sections/SchoolEnvFeedback";
import SideTab from "../../typeform/SideTab";

export const SCHOOL_ENV_TYPE = 'school';

const SchoolEnvironmentPage = () => {
  const [toggleForm, setToggleForm] = useState(true);

  const activeInstruments = useQuery([ACTIVE_INSTRUMENTS, SCHOOL_ENV_TYPE], () => getActiveInstruments(SCHOOL_ENV_TYPE), {
    select: (data) => data.data.data,
    placeholderData: [],
    staleTime: Infinity,
  });

  return (
    <DashboardLayout title={'School environment and leadership survey'}>
      <div className={'flex'}>
        <SideTab
          formId = {'pRLz3RXY'}
        />
        <div className={'lg:w-5/6'}>
          <InstrumentForm
            id={'form'}
            showToggleButton={0 !== activeInstruments.data.length}
            type={SCHOOL_ENV_TYPE}
            initialState={{
              type: SCHOOL_ENV_TYPE,
              survey_version: '',
              expected_responses: ''
            }}
            toggle={toggleForm}
            setToggle={setToggleForm}
          />
          <InstrumentMonitor id={'monitor'} type={SCHOOL_ENV_TYPE} data={activeInstruments.data}/>
          <SchoolEnvFeedback/>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default SchoolEnvironmentPage;
