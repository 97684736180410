import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';

const FeatureFlagModal = ({ toggle, text, id }: ModalToggleProps & FeatureFlagModalProps) => {
  return (
    <Modal>
      <Modal.Body>
        <div>
          <div className={'mt-4 mb-8'}>{text}</div>
          <div className="flex">
            <p className="mx-auto mt-4">
              <Link
                onClick={() =>
                  posthog.capture('Seen login model', {
                    distinctId: id,
                    $set: { has_user_seen_model_survey_after_login: true },
                  })
                }
                to={'/student-surveys'}
              >
                <Modal.ConfirmButton label={'Click here to get started'} />
              </Link>
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            posthog.capture('Seen login model', {
              distinctId: id,
              $set: { has_user_seen_model_survey_after_login: true },
            });
            toggle(false);
          }}
          label={'Close'}
        />
      </Modal.Footer>
    </Modal>
  );
};

type FeatureFlagModalProps = {
  text?: string;
  id?: string;
};
export default FeatureFlagModal;
