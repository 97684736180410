import React, { useState } from 'react';
import { VideoSection } from '../sections';
import { Section } from '../../common';
import { CommunityNewsfeed } from '../../index';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';
import { Me } from '../../../@types/Entity/Me';
import GTTNiotLogo from '@app/asset/images/GTT_NIoT.png';
import { LinkStyled } from '../../../global/link/link.styled';
import { useQuery } from 'react-query';
import { THINKIFIC_SSO } from '../../../service/queryKeys';
import { getThinkificSSO } from '../../../service/api';
import { THINKIFIC_LINKS } from '../../../service/links';
import { niotAdminAccounts, niotRole, TAG_ECT, TAG_INDUCTION_LEAD, TAG_MENTOR } from '../../../resources/roles';
import VideoAlert from '../../../guides/components/videoAlert';
import { Link } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import DefaultVideoThumbnail from '@app/asset/images/DefaultVideoThumbnail.png';
import useModal from '../../../hooks/useModal';
import VideoModal from '../../instrument/modals/videoModal';

const NiotDashboard = ({ me }: { me: Me }) => {
  const [showAlert, setShowAlert] = useState<boolean>(true);
  const [videoLink, setVideoLink] = useState<string>('');

  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
    refetchIntervalInBackground: true,
  });

  const userTag = niotRole(me);

  const classes = ' border-2 p-4 rounded-md cursor-pointer hover:bg-gray-50 ';

  const [videoModal, toggleVideoModal, setVideoModalProps] = useModal(
    (props: any) => (
      <VideoModal
        videoLink={
          me.is_early_career_teacher
            ? 'https://vimeo.com/910031404/a4b742954d'
            : me.mentor && !me.is_induction_lead
              ? 'https://vimeo.com/1012729055/b72805b7d2?'
              : me.is_induction_lead
                ? videoLink
                : ''
        }
        {...props}
      />
    ),
    {
      title: me.mentor && !me.is_induction_lead ? 'Mentor Guide 2024' : 'Tutorial',
      size: 'lg',
    }
  );
  const [mentorVideoModal, toggleMentorVideoModal, setMentorModalProps] = useModal(
    (props: any) => (
      <VideoModal
        videoLink={me.mentor && !me.is_induction_lead ? 'https://vimeo.com/1012729097/819ca3237b?share=copy' : ''}
        {...props}
      />
    ),
    {
      title: 'Mentor Self Study on GTT',
      size: 'lg',
    }
  );

  const niotCohortLink = `${thinkificSSO.isFetched ? thinkificSSO.data : ''}${
    me.niot_cohort === 1
      ? THINKIFIC_LINKS[me.is_early_career_teacher ? 'selfStudyEctYear2' : me.mentor ? 'selfStudyMentorCourse1' : '']
      : me.niot_cohort === 2
        ? THINKIFIC_LINKS[
          me.is_early_career_teacher ? 'selfStudyEctCourse2024' : me.mentor ? 'selfStudyMentorCourse2024' : ''
          ]
        : ''
  }`;

  const extraButtons: boolean = me.mentee_group_id != null || me.niot_cohort != null;

  const data = () => {
    if (me.is_early_career_teacher) {
      return {
        title: 'Quick links',
        videoLink: 'https://vimeo.com/910031404/a4b742954d',
        content: (
          <>
            <Section className={'field-mb'}>
              <VideoAlert
                classes={'w-full'}
                setVideo={undefined}
                showHideForever={false}
                showHide={false}
                data={{
                  title: 'Help us with important research on mentoring and feedback!',
                  id: 'niot_ect_feedback_link',
                  description: (
                    <>
                      <p className={'mb-4'}>
                        Check your email for the NIoT Research Team’s invite to participate in a study about mentoring
                        and feedback, sent September 19th, and consider taking part!
                      </p>
                      <p>
                        Click the personalised link in your email (the quickest way) or{' '}
                        <LinkStyled href={'https://forms.office.com/e/7Z7UAusAYJ'} target={'_blank'} rel={'noreferrer'}>
                          click here
                        </LinkStyled>{' '}
                        (non-personalised).
                      </p>
                    </>
                  ),
                }}
              />
              <div
                className={`grid sm:grid-cols-2 sm:grid-rows-${extraButtons ? '2' : '1'} lg:grid-cols-${
                  extraButtons ? '3' : '2'
                } 2xl:grid-cols-${extraButtons ? '4' : '3'} lg:grid-rows-1 gap-5`}
              >
                <div className={classes} onClick={() => toggleVideoModal(true)}>
                  <p className={'font-bold mb-2'}>Tutorial</p>
                  <img
                    width={'75%'}
                    src={DefaultVideoThumbnail}
                    alt="Thumbnail"
                    className={'mx-auto border rounded'}
                    style={{ borderColor: '#68a4c5' }}
                  />
                </div>
                <div className={classes}>
                  <a
                    href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['courses'] : '#'}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <p className={'font-bold mb-4'}>Self-study modules</p>
                    <p className={'italic'}>Check out this week’s learning</p>
                  </a>
                </div>
                {me.mentee_group_id ? (
                  <div className={classes}>
                    <Link to={routeBuilder('mentoringTeamsWorkspace').generate(me.mentee_group_id)}>
                      <p className={'font-bold mb-4'}>Mentor meetings</p>
                      <p className={'italic'}>Notes and feedback from your mentor</p>
                    </Link>
                  </div>
                ) : (
                  <div className={classes}>
                    <p className={'font-bold mb-4'}>Mentor meetings</p>
                    <p className={'italic'}>
                      When you&apos;re paired with your mentor, you&apos;ll be able to access the notes and feedback
                      from your mentor
                    </p>
                  </div>
                )}
                {me.niot_cohort && (
                  <div className={classes}>
                    <a rel="noreferrer" target={'_blank'} href={niotCohortLink}>
                      <p className={'font-bold mb-4'}>Introduction to the ECF Programme</p>
                      <p className={'italic'}>Check out the guide</p>
                    </a>
                  </div>
                )}
              </div>
            </Section>
          </>
        ),
        videoAlert: (
          <>
            <VideoAlert
              customVideoTitle={'Welcome!'}
              customButtonMessage={'Hide this message'}
              showVideo
              setVideo={setShowAlert}
              showHideForever={false}
              showHide
              data={{
                videoLink: 'https://vimeo.com/859747474/a8e121a39c',
                title: '',
                id: 'niot_ect',
                description: (
                  <>
                    <p className={'font-bold'}>
                      We are thrilled to have you on board and can&apos;t wait to support you in your journey towards
                      becoming an exceptional educator.
                    </p>
                    <br />
                    <p className={'font-bold'}>
                      As a participant in the NIoT Early Career Framework programme (ECF), you will gain invaluable
                      skills and knowledge through our cutting-edge online tools.
                    </p>
                    <br />
                    <p className={'font-bold'}>
                      Start by watching your welcome video from Katy Micklewright, Head of ECF Faculty and then check
                      out the ECF Programme Introduction.
                    </p>
                    <br />
                    <div className={'flex'}>
                      <div className={'w-max h-full'}>
                        <LinkStyled
                          asButton
                          mainColor={'primary'}
                          href={niotCohortLink}
                          target={'_blank'}
                          className={'m-auto inline-block '}
                        >
                          <p className="break-keep w-max">Go to introduction</p>
                        </LinkStyled>
                      </div>
                    </div>
                  </>
                ),
              }}
            />
          </>
        ),
      };
    }

    if (me.mentor && !me.is_induction_lead) {
      return {
        title: 'Quick links',
        videoLink: 'https://vimeo.com/910031533/b9d3765488',
        videoAlert: (
          <>
            <VideoAlert
              customVideoTitle={'Welcome!'}
              customButtonMessage={'Hide this message'}
              showVideo
              setVideo={setShowAlert}
              showHideForever={false}
              showHide
              data={{
                videoLink: 'https://vimeo.com/859946578/ad508acdb5',
                title: '',
                id: 'niot_mentor_not_il',
                description: (
                  <>
                    <p className={'font-bold'}>
                      We value the role of ECT mentors extremely highly and have developed a programme for you which is
                      respectful of your time and expertise.
                    </p>
                    <br />
                    <p className={'font-bold'}>
                      As a mentor on the NIoT Early Career Framework programme (ECF), you will have access to tools
                      which allow you to support your ECT in developing their teaching skills and knowledge through our
                      cutting-edge online tools.
                    </p>
                    <br />
                    <p className={'font-bold'}>
                      Start by watching your welcome video from Katy Micklewright, Head of ECF Faculty and then check
                      out the ECF Programme Introduction.
                    </p>
                    <br />
                    <div>
                      <LinkStyled asButton href={niotCohortLink} target={'_blank'} className={'mx-auto inline-block'}>
                        Go to introduction
                      </LinkStyled>
                    </div>
                  </>
                ),
              }}
            />
          </>
        ),
        content: (
          <Section className={'field-mb'}>
            <div className="grid grid-cols-1 grid-rows-3 md:grid-rows-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 lg:grid-rows-1 gap-5">
              <div
                className={classes}
                onClick={() => {
                  setVideoModalProps({ title: 'Mentor Guide 2024' });
                  toggleVideoModal(true);
                }}
              >
                <p className={'font-bold mb-2'}>Mentor Guide 2024</p>
                <img
                  src={DefaultVideoThumbnail}
                  width={'75%'}
                  alt="Thumbnail"
                  className={'mx-auto border rounded'}
                  style={{ borderColor: '#68a4c5' }}
                />
              </div>
              <div
                className={classes}
                onClick={() => {
                  setMentorModalProps({ title: 'Mentor Self Study on GTT' });
                  toggleMentorVideoModal(true);
                }}
              >
                <p className={'font-bold mb-2'}>Mentor Self Study on GTT</p>
                <img
                  src={DefaultVideoThumbnail}
                  width={'75%'}
                  alt="Thumbnail"
                  className={'mx-auto border rounded'}
                  style={{ borderColor: '#68a4c5' }}
                />
              </div>
              <div className={classes}>
                <a
                  href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['courses'] : '#'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <p className={'font-bold mb-4'}>Your self-study modules</p>
                  <p className={'italic'}>Check out this week’s learning</p>
                </a>
              </div>
              <div className={classes}>
                <Link to={routeBuilder('mentoringTeams').generate()}>
                  <p className={'font-bold mb-4'}>ECT self-study modules</p>
                  <p className={'italic'}>Is your ECT on track? Click your ECT&apos;s shared workspace</p>
                </Link>
              </div>
              <div className={classes}>
                <Link to={routeBuilder('mentoringTeams').generate()}>
                  <p className={'font-bold mb-4'}>Arrange a feedback session</p>
                  <p className={'italic'}>Create a meeting and add an agenda for your ECT</p>
                </Link>
              </div>
              {me.niot_cohort && (
                <div className={classes}>
                  <a rel="noreferrer" href={niotCohortLink} target={'_blank'}>
                    <p className={'font-bold mb-4'}>Introduction to the ECF Programme</p>
                    <p className={'italic'}>Check out the guide</p>
                  </a>
                </div>
              )}
            </div>
          </Section>
        ),
      };
    }

    if (me.is_induction_lead) {
      return {
        title: 'Quick links',
        videoLink: 'https://vimeo.com/910031645/778579dfa8',
        content: (
          <Section className={'field-mb'}>
            <div className="grid grid-cols-1 grid-rows-3 md:grid-rows-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 lg:grid-rows-1 gap-5">
              <div
                className={classes}
                onClick={() => {
                  setVideoLink('https://vimeo.com/1005433286/af616c29ad');
                  toggleVideoModal(true);
                }}
              >
                <p className={'font-bold mb-2'}>NIOT Induction Tutor briefing 2024</p>
                <img
                  src={DefaultVideoThumbnail}
                  alt="Thumbnail"
                  className={'border rounded'}
                  style={{ borderColor: '#68a4c5' }}
                />
              </div>
              <div
                className={classes}
                onClick={() => {
                  setVideoLink('https://vimeo.com/1013991816/40d38c8281');
                  toggleVideoModal(true);
                }}
              >
                <p className={'font-bold mb-2'}>Induction Tutor Guide 2024</p>
                <img
                  src={DefaultVideoThumbnail}
                  alt="Thumbnail"
                  className={'border rounded'}
                  style={{ borderColor: '#68a4c5' }}
                />
              </div>
              <div className={classes}>
                <Link to={routeBuilder('userManagement').generate()}>
                  <p className={'font-bold mb-4'}>Pair an ECT and Mentor</p>
                  <p className={'italic'}>Check both the ECT and the mentor and click Assign Mentor</p>
                </Link>
              </div>
              <div className={classes}>
                <Link to={routeBuilder('userManagement').generate()}>
                  <p className={'font-bold mb-4'}>Track progress through self-study modules</p>
                  <p className={'italic'}>Are your ECTs and Mentors on track?</p>
                </Link>
              </div>
              <div className={classes}>
                <Link to={routeBuilder('mentoringTeams').generate()}>
                  <p className={'font-bold mb-4'}>Arrange a feedback session</p>
                  <p className={'italic'}>Join a meeting or create a new one</p>
                </Link>
              </div>
              {/*<div className={classes}>
                <a href={'#'}>
                  <p className={'font-bold mb-4'}>
                    Monitor mentoring meetings </p>
                  <p className={'italic'}>
                    What are your ECTs working on right now?
                  </p>
                </a>
              </div>*/}
              <div className={classes}>
                <a
                  href={
                    'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/DPS_GTT%20Resources/2024%20Induction%20lead%20Introduction%20booklet.pdf'
                  }
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <p className={'font-bold mb-4'}>Early career programme 2024</p>
                  <p className={'italic'}>Download the guide</p>
                </a>
              </div>
            </div>
          </Section>
        ),
        videoAlert: (
          <>
            <VideoAlert
              customVideoTitle={'Welcome!'}
              customButtonMessage={'Hide this message'}
              showVideo
              setVideo={setShowAlert}
              showHideForever={false}
              showHide
              data={{
                videoLink: 'https://vimeo.com/859903964/da821c1871',
                title: '',
                id: 'niot_induction_lead',
                description: (
                  <>
                    <p className={'font-bold'}>
                      We value the role of our induction tutors extremely highly and have developed a programme and
                      platform to ensure that you can easily manage the progress of your ECTs in one place.
                    </p>
                    <br />
                    <p className={'font-bold'}>
                      To begin, please watch the welcome video from Katy Micklewright, Head of ECF Faculty.
                    </p>
                    <br />
                    <div className={'flex'}>
                      <div className={'w-max h-full'}>
                        <LinkStyled
                          asButton
                          mainColor={'primary'}
                          href={
                            'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/DPS_GTT%20Resources/2024%20Induction%20lead%20Introduction%20booklet.pdf'
                          }
                          target={'_blank'}
                          className={'m-auto inline-block '}
                        >
                          <p className="break-keep w-max">Introduction booklet 2024</p>
                        </LinkStyled>
                      </div>
                    </div>
                  </>
                ),
              }}
            />
          </>
        ),
      };
    }

    if (me.is_associate_college) {
      return {
        title: 'Welcome to the Early Careers Programme with the National Institute of Teaching (NIoT).',
        content: (
          <div className="col-span-3">
            <Section size={'md'} containerClassName={'h-full'} className={'col-span-3'}>
              <p className={'font-bold text-center'}>
                We value our {!me.is_niot_trust_admin ? 'Associate Colleges' : 'partners'} extremely highly and have
                developed a
                programme and platform to ensure that you can easily manage the progress of your ECTs and Mentors in one
                place.
              </p>
              <br/>
              <div className={'flex justify-center my-8'}>
                <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'}/>
              </div>
            </Section>
          </div>
        ),
      };
    }

    if (me.is_regional_lead || me.is_niot || (me.is_niot && me.is_ceo_admin)) {
      return {
        title:
          me.is_niot && me.is_ceo_admin
            ? 'Welcome to our Early Careers and School Trust CEO Programmes with the Great Teaching Toolkit'
            : 'Welcome to our Early Careers Programme with the Great Teaching Toolkit',
        content: (
          <div className="col-span-3">
            <Section size={'md'} containerClassName={'h-full'}>
              <p className={'font-bold text-center'}>
                {me.is_niot && me.is_ceo_admin
                  ? 'We’ve developed an integration between our programmes and the GTT platform to ensure you can easily manage the progress of your CEOs, Schools, ECTs and Mentors in one place.'
                  : 'We’ve developed a programme and platform to ensure that you can easily manage the progress of your Schools, ECTs and Mentors in one place.'}
              </p>
              <br/>
              <div className={'flex justify-center my-8'}>
                <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'}/>
              </div>
            </Section>
          </div>
        ),
      };
    }
  };

  return (
    <>
      {videoModal}
      {mentorVideoModal}
      {showAlert && <div className="w-full">{data()?.videoAlert}</div>}
      <TagSection me={me} title={data()!.title} content={data()!.content}/>
      {['INDUCTION_LEAD', 'MENTOR', 'ECT'].includes(userTag) ? (
        <div className="grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 gap-8 field-mb">
          {[TAG_ECT, TAG_MENTOR, TAG_INDUCTION_LEAD].includes(userTag) ? <></> : <CommunityNewsfeed width={'lg'}/>}
          {!data()?.videoAlert && (
            <Section size={null} headline={'GTT Tutorial for the ECF Programme'} className={'w-full lg:w-2/4'}>
              <VideoSection video={data()!.videoLink!}/>
            </Section>
          )}
        </div>
      ) : niotAdminAccounts.includes(niotRole(me)) ? (
        <></>
      ) : (
        <CommunityNewsfeed width={'lg'} />
      )}
    </>
  );
};

const TagSection = ({ me, title, content }: { me: Me; title: string; content: React.ReactElement }) => {
  return (
    <>
      {(me.is_early_career_teacher || me.mentor || me.is_induction_lead) && (
        <div className={'block-with-sub-container mb-5'}>
          <MeetingsOverview me={me} />
        </div>
      )}
      <div
        className={`block-with-sub-container mb-5 ${
          niotAdminAccounts.includes(niotRole(me)) ? 'w-full xl:w-2/3 ' : ''
        }`}
      >
        <h5 className={`${niotAdminAccounts.includes(niotRole(me)) ? 'text-center ' : ''}`}>{title}</h5>
        {me.is_early_career_teacher || me.mentor || me.is_induction_lead ? (
          <>{content}</>
        ) : (
          <div className={'grid grid-cols-3 gap-4 mt-5'}>{content}</div>
        )}{' '}
      </div>
    </>
  );
};

export default NiotDashboard;
