import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import Algolia from './components/algolia';
import { useLocation } from 'react-router-dom';
import TextResourcePage from './components/textResourcePage';

const ResourcePage = ({ isFree }: { isFree?: boolean }) => {
  const location = useLocation();

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
    const fragmentString = location.hash.substring(1); // Extracts the string after the hash
    setShowResourceSubpage(fragmentString);
  }, [location]);

  const [showResourceSubpage, setShowResourceSubpage] = useState<any>();

  return (
    <>
      {showResourceSubpage ? (
        <TextResourcePage slug={showResourceSubpage} />
      ) : (
        <DashboardLayout title={'Resources'}>
          <Algolia isFree={isFree} />
        </DashboardLayout>
      )}
    </>
  );
};
export default ResourcePage;
