import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { AddButton } from '../../global/button/common';
import 'react-calendar/dist/Calendar.css';
import MeetingsOverview from './components/MeetingsOverview';
import TeamsList from './components/TeamsList';
import TeamFormModal, { TeamFormData } from './components/modals/TeamForm.modal';
import MeetingsFormModal, { MeetingsFormData } from './components/modals/MeetingsForm.modal';
import { useQuery } from 'react-query';
import { ME, MY_GROUPS, PATHWAYS_ELEMENTS, THINKIFIC_SSO } from '../../service/queryKeys';
import {
  getMe,
  getPathwaysElements,
  getTeacherMemberGroups,
  getTeacherMemberMentees,
  getThinkificSSO,
} from '../../service/api';
import { isLoggedIn } from '../../service/auth';
import TeamsModeContext, { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../contexts/TeamsContext/TeamsModeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import routeBuilder from '../../service/routeBuilder';
import VideoAlert from '../../guides/components/videoAlert';
import { LinkStyled } from '../../global/link/link.styled';
import { THINKIFIC_LINKS } from '../../service/links';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../resources/roles';
import { ApiResponse } from '../../@types/global';
import { Group } from '../../@types/Entity/Group';

export default function GreatTeachingTeamsPage() {
  const [isGroupFormModalOpen, setIsGroupFormModalOpen] = useState(false);
  const [isMeetingsFormModalOpen, setIsMeetingsFormModalOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState<TeamFormData | null>(null);
  const [groupMeetingsToEdit, setGroupMeetingsToEdit] = useState<MeetingsFormData | null>(null);

  const location = useLocation();

  const teamsMode =
    routeBuilder('greatTeachingTeams').route === location.pathname
      ? TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams
      : TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams;

  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
    refetchIntervalInBackground: true,
  });

  const myGroupsQuery = useQuery<ApiResponse<Group[]>>(
    [MY_GROUPS, { mode: teamsMode }],
    () =>
      TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
        ? getTeacherMemberGroups()
        : getTeacherMemberMentees(),
    {
      placeholderData: {
        data: [],
      },
    }
  );

  const includeElement = '1.2';
  const getPathwaysElementsQuery = useQuery(
    [PATHWAYS_ELEMENTS, includeElement],
    () => getPathwaysElements(includeElement),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const me = getMeQuery.data?.data.me ?? null;

  const [showVideoAlert, setShowVideoAlert] = useState<boolean>(
    !me!.show_video.includes('mentoring_induction_tutors') && me!.mentor && me!.is_induction_lead
  );

  const [showMentorAlert, setShowMentorAlert] = useState<boolean>(
    !me!.show_video.includes('observations_meetings') && me!.show_video && me!.mentor && !me!.is_induction_lead
  );

  const [showTeamsAlert, setShowTeamsAlert] = useState<boolean>(
    !me!.show_video.includes('great_teaching_teams_header') && !me!.is_induction_lead && !me!.mentor
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (me?.role === ROLE_TEACHER_FREE && myGroupsQuery) {
      const groupId =
        myGroupsQuery?.data && myGroupsQuery.data.data.length > 0 ? myGroupsQuery.data.data[0].id : undefined;
      if (groupId) navigate(routeBuilder('greatTeachingTeamsWorkspace').generate(groupId, 3));
    }
  }, [myGroupsQuery]);

  if (me?.role === ROLE_TEACHER_FREE) {
    return (
      <DashboardLayout title={'Team workspace'}>
        <></>
      </DashboardLayout>
    );
  }

  return (
    <TeamsModeContext.Provider value={{ teamsMode: teamsMode }}>
      {isGroupFormModalOpen && (
        <TeamFormModal
          toggle={() => {
            setGroupToEdit(null);
            setIsGroupFormModalOpen(false);
          }}
          teamToEdit={groupToEdit}
        />
      )}
      {isMeetingsFormModalOpen && (
        <MeetingsFormModal
          toggle={() => {
            setGroupToEdit(null);
            setIsMeetingsFormModalOpen(false);
          }}
          groupMeetingsToEdit={groupMeetingsToEdit!}
        />
      )}

      <DashboardLayout
        title={TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? 'Teams' : 'Early Career Framework'}
        pageAction={
          (TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode &&
            (me?.great_teaching_lead ||
              (me?.role === ROLE_TEACHER && me?.role !== ROLE_TEACHER_FREE) ||
              me?.role === ROLE_SCHOOL_COORDINATOR ||
              (me?.role === ROLE_TEACHER && me?.role !== ROLE_TEACHER_FREE) ||
              me?.role === ROLE_ASSISTANT_GT_COORDINATOR)) ||
          (TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode && (me?.mentor && !me?.is_induction_lead)) ? (
            <AddButton
              label={`Create ${TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? 'Team' : 'Mentee'}`}
              onClick={() => {
                setGroupToEdit(null);
                setIsGroupFormModalOpen(true);
              }}
            />
          ) : null
        }
      >
        {showTeamsAlert && (
          <div>
            <VideoAlert
              setVideo={setShowTeamsAlert}
              showHideForever
              data={{
                title: 'Great Teaching Teams',
                id: 'great_teaching_teams_header',
                description: (
                  <>
                    <p className={'mb-2'}>
                      Welcome to Great Teaching Teams! Collaborate with colleagues to improve an element of Great
                      Teaching, working through Development cycles together. Arrange a regular time to meet and then
                      work through development activities in your team&lsquo;s shared workspace.
                    </p>
                    {(myGroupsQuery.data?.data.length ?? 0) < 1 && (
                      <p>
                        To use this page, you will first need to be assigned to a team by your school&lsquo;s
                        coordinator. Once they have done this, your team will appear below and you&lsquo;ll be able to
                        access the team features.
                      </p>
                    )}
                  </>
                ),
              }}
              userId={me!.id}
            />
          </div>
        )}
        {showVideoAlert && (
          <VideoAlert
            userId={me!.id}
            setVideo={setShowVideoAlert}
            showHideForever
            data={{
              title: 'Mentoring for Induction tutors',
              id: 'mentoring_induction_tutors',
              description: (
                <>
                  <div>
                    If you’re an Induction tutor and also a mentor at your school, you can now access the Mentoring
                    management page to arrange meetings and observations with your ECTs.
                  </div>
                  <div className={'my-1'}>
                    Please continue to use the Early Career Framework page to pair Mentors and ECTs and to monitor
                    self-study progress.
                  </div>
                  {/*<div>
                    Here’s a short video introduction to the Mentoring page and shared workspaces for each ECT you
                    mentor.
                  </div>*/}
                </>
              ),
              /*
                            videoLink: 'https://vimeo.com/867908309/44c7b8363e?',
              */
            }}
          />
        )}
        <div className={'flex-1 block-with-sub-container'}>
          <MeetingsOverview title={'Meetings overview'} me={me} />

          {showMentorAlert && (
            <div className={'my-8'}>
              <VideoAlert
                data={{
                  title: 'Observations & Meetings',
                  id: 'observations_meetings',
                  description: (
                    <>
                      <div className={'mb-2'}>
                        When observing your ECTs, note down your observations following the Six-step observation and
                        feedback model (more information can be found in your E-learn&nbsp;
                        <LinkStyled
                          href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['mentorsOnboarding'] : '#'}
                          target={'_blank'}
                          className={'mx-auto inline-block'}
                        >
                          HERE
                        </LinkStyled>
                        ).
                      </div>
                      <div>
                        When meeting with your ECT summarise your feedback, add note files (e.g. Word), plan and set
                        actions in the ECF Shared Workspace.
                      </div>
                    </>
                  ),
                }}
                setVideo={setShowMentorAlert}
                userId={me!.id}
                showHideForever
              />
            </div>
          )}

          <TeamsList
            onEditTeamClick={(group) => {
              setGroupToEdit(group);
              setIsGroupFormModalOpen(true);
            }}
            onEditMeetingsClick={(group) => {
              setGroupMeetingsToEdit(group);
              setIsMeetingsFormModalOpen(true);
            }}
            me={me}
          />
        </div>
      </DashboardLayout>
    </TeamsModeContext.Provider>
  );
}
