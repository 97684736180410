import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { useLocation, useParams } from 'react-router-dom';
import MeetingsList from './components/MeetingsList';
import { useQuery } from 'react-query';
import { ACADEMIC_YEARS, ME } from '../../service/queryKeys';
import { getAcademicYears, getMe } from '../../service/api';
import { isLoggedIn } from '../../service/auth';
import TeamsModeContext, { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../contexts/TeamsContext/TeamsModeContext';
import routeBuilder from '../../service/routeBuilder';
import Selector from '../../form/select/selector';
import { Option } from '../../@types/global';
import dayjs from 'dayjs';
import SelfStudy from './components/SelfStudy';
import VideoAlert from '../../guides/components/videoAlert';
import { niotRole, ROLE_TEACHER_FREE } from '../../resources/roles';

const today = dayjs();

export default function GreatTeachingTeamsWorkspacePage() {
  const { id } = useParams();
  const location = useLocation();

  const [academicYear, setAcademicYear] = useState<any>();

  const academicYearsOptionsQuery = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    select: (data) =>
      data?.data.map((academicYear) => ({
        label: academicYear.name,
        value: academicYear.id,
        original: {
          ...academicYear,
          startDate: dayjs(academicYear.startDate, 'DD/MM/YYYY').startOf('day'),
          endDate: dayjs(academicYear.endDate, 'DD/MM/YYYY').endOf('day'),
        },
      })),
  });

  const defaultAcademicYear = academicYearsOptionsQuery.data?.find(
    (option) =>
      option.original.startDate.toDate() <= today.toDate() &&
      option.original.endDate.endOf('day').toDate() >= today.toDate()
  );

  const selectedAcademicYear = academicYear ?? defaultAcademicYear ?? null;

  const teamsMode =
    routeBuilder('greatTeachingTeamsWorkspace').generate(id) === location.pathname
      ? TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams
      : TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams;

  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me ?? null;

  const isFree = me?.role === ROLE_TEACHER_FREE;

  const userTag = niotRole(me);

  const [showTeamWorkspaceAlert, setShowTeamWorkspaceAlert] = useState<boolean>(
    !me!.show_video.includes('gtteams_workspace_header') && userTag === 'null'
  );

  return (
    <TeamsModeContext.Provider value={{ teamsMode: teamsMode }}>
      <DashboardLayout
        parent={
          TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
            ? {
                id: 'greatTeachingTeams',
                label: 'Great Teaching Teams',
              }
            : {
                id: 'mentoringTeams',
                label: 'Early Career Framework',
              }
        }
        title={
          TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
            ? isFree
              ? `${me?.first_name}'s team`
              : 'Team workspace'
            : 'ECF shared workspace'
        }
      >
        {isFree && (
          <div>
            <VideoAlert
              setVideo={undefined}
              showHideForever={false}
              showHide={false}
              starterTemplate={isFree}
              data={{
                title: '',
                id: 'starter_template_teams',
                description: (
                  <>
                    <p className={'mb-4'}>
                      In this example, a team of teachers are meeting three times to launch their professional learning,
                      to review their progress through the Questioning course, and then to get feedback on their
                      practice.
                    </p>
                    <p>
                      Great Teaching Teams is built on a model of reciprocal coaching, in groups of 4-6, encouraging
                      teachers to share their expertise. Structure your learning over a term, or academic year, with
                      pre-requisite actions, agendas and resources to get better, together.
                    </p>
                  </>
                ),
              }}
              userId={me!.id}
            />
          </div>
        )}
        {showTeamWorkspaceAlert && !isFree && (
          <div>
            <VideoAlert
              setVideo={setShowTeamWorkspaceAlert}
              showHideForever
              data={{
                title: 'Teams workspace',
                id: 'gtteams_workspace_header',
                description: (
                  <>
                    <p className={'mb-2'}>
                      When you&lsquo;re ready for your GT Team meeting, click on &quot;Start meeting&quot; to access the
                      meeting agenda and work through the activities.
                    </p>
                    <p>
                      One person from your group should act as scribe, start the meeting and record the relevant
                      information. To save information, click &quot;Finish meeting&quot;. Only one person can record at
                      a time but anyone in the team can edit entries later. Information recorded here will appear in
                      each team member&lsquo;s account.
                    </p>
                  </>
                ),
              }}
              userId={me!.id}
            />
          </div>
        )}
        <div className={'block-with-sub-container'}>
          {!isFree && (
            <div className={'flex justify-start mt-4'}>
              <div style={{ minWidth: '200px' }}>
                <Selector
                  id={'academic-year'}
                  placeholder={'Academic year'}
                  label={'Academic year'}
                  hideLabel
                  options={academicYearsOptionsQuery.data}
                  onChange={(selected: Option) => setAcademicYear(selected)}
                  value={selectedAcademicYear}
                  isClearable={false}
                />
              </div>
            </div>
          )}

          {TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode ? (
            <div className={'xl:flex'}>
              <div className={'xl:flex-1 xl:mr-4'}>
                <SelfStudy
                  groupId={id!}
                  me={getMeQuery.data!.data.me}
                  selectedAcademicYear={selectedAcademicYear}
                />
              </div>
            </div>
          ) : (
            <></>
            // <div className={'xl:flex'}>
            //   <div className={'xl:flex-1 xl:mr-4'}>
            //     <SharedResources />
            //   </div>
            //   <div className={'xl:flex-1'}>
            //     <TeamMessages />
            //   </div>
            // </div>
          )}

          <MeetingsList
            isFree={isFree}
            me={getMeQuery.data?.data.me ?? null}
            group={id!}
            selectedAcademicYear={selectedAcademicYear}
            teamsMode={teamsMode}
          />
        </div>
      </DashboardLayout>
    </TeamsModeContext.Provider>
  );
}
