import React, { useState } from 'react';
import DashboardLayout from '../../../layout/dashboard';
import { dimensions, DimensionType, ElementType } from '../resources/resources';
import { Section } from '../../common';
import Accordion from '../../../global/accordion/accordion';
import { SectionInAccordion } from '../../pathway/components';
import ElementCard from './elementCard';
import modelDescription from '@app/asset/images/mfgt_page/GTT_Model Descriptions.png';
import assetImage from '@app/asset/images/mfgt_page/Asset.png';

const ModelPage = () => {
  const [accordionActive, setAccordionActive] = useState<number | undefined>(undefined);

  return (
    <DashboardLayout title={'Model for Great Teaching'}>
      <Section className={'mb-4'}>
        <div className={''}>
          <div className={'flex justify-center items-center'}>
            <img className={'m-auto my-8 xl:w-3/4 w-full'} src={modelDescription} />
          </div>
          <div className={'w-3/4 2xl:w-1/2 mx-auto mt-12'}>
            We have reviewed existing research studies and frameworks that are relevant to the components and routes to
            improvement of teacher effectiveness. In summary, we have identified four priorities for teachers who want
            to help their students learn more:
            <ol className={'list-decimal mt-4 w-3/4 mx-auto'}>
              <li> Understanding the content they are teaching and how it is learnt;</li>
              <li> Creating a supportive environment for learning;</li>
              <li> Managing the classroom to maximise opportunity to learn;</li>
              <li> Presenting content, activities and interactions that activate their students’ thinking.</li>
            </ol>
          </div>
        </div>
      </Section>
      <Section className={'mb-4'}>
        <div className="md:grid 2xl:grid-cols-2 md:grid-cols-1 2xl:grid-rows-1">
          <img className={'lg:w-1/2 mb-8 md:my-8 2xl:my-auto m-auto 2xl:w-3/4 3xl:w-1/2'} src={assetImage} />
          <div className={'w-3/4 2xl:w-2/3 mx-auto 2xl:mx-0 2xl:my-auto'}>
            In the classroom we can think of interactions between teachers, students and curriculum content. Each pair
            of such interactions (teacher-student, teacher-curriculum, student-curriculum) corresponds with a dimension
            in the Model for Great Teaching above. The remaining dimension (Dimension 3), which represents the time and
            opportunity for learning, can be thought of as a &apos;multiplier&apos; for the impact of these
            interactions: the more time given to these interactions, the more learning happens.
          </div>
        </div>
      </Section>
      <Section headline={'Dimensions and elements'}>
        {dimensions.map((dimension: DimensionType, index: number) => (
          <div key={index}>
            <Accordion
              dimension={dimension}
              title={dimension.title}
              isOpen={index === accordionActive}
              handleOpen={() => {
                index === accordionActive ? setAccordionActive(undefined) : setAccordionActive(index);
              }}
            >
              <SectionInAccordion className={'mb-4'}>
                <p className={'text-xl font-semibold my-4'}>{dimension.description_title}</p>
                <ul className={'mx-16 2xl:w-3/5 2xl:pl-2 ml-16 2xl:pr-12 list-decimal list-inside mt-8'}>
                  {dimension.description}
                </ul>
              </SectionInAccordion>
              <div
                className={`m-auto p-auto w-full h-4/5 grid grid-cols-1 gap-4 grid-rows-${
                  dimension.elements.length
                } xl:grid-rows-${Math.ceil(
                  dimension.elements.length / 2
                )} xl:grid-cols-2 2xl:grid-cols-3 2xl:grid-rows-${Math.ceil(dimension.elements.length / 3)} pb-12`}
              >
                {dimension.elements.map((element: ElementType, index: number) => (
                  <ElementCard key={index} element={element} dimension={dimension} />
                ))}
              </div>
            </Accordion>
          </div>
        ))}
      </Section>
    </DashboardLayout>
  );
};

export default ModelPage;
