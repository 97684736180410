import { generatePath } from 'react-router';

const routeBuilder = (path: PathType) => {
  const generate = (path: string, parameters = {}) => {
    return generatePath(path, parameters);
  };

  const routes: RouteType = {
    login: {
      route: '/login',
      generate: () => {
        return generate('/login');
      },
    },
    register: {
      route: '/register',
      generate: () => {
        return generate('/register');
      },
    },
    registerConfirmation: {
      route: '/register-confirmation',
      generate: () => {
        return generate('/register-confirmation');
      },
    },
    logout: {
      route: '/logout',
      generate: () => {
        return generate('/logout');
      },
    },
    home: {
      route: '/',
      generate: () => {
        return generate('/');
      },
    },
    survey: {
      route: '/survey',
      generate: (params: Record<string, string>) => {
        return generate(`/survey${generateExtraQueryParams(params)}`);
      },
    },
    classManagement: {
      route: '/class-management',
      generate: () => {
        return generate('/class-management');
      },
    },
    userManagement: {
      route: '/user-management',
      generate: () => {
        return generate('/user-management');
      },
    },
    groupManagement: {
      route: '/group-management',
      generate: () => {
        return generate('/group-management');
      },
    },
    resetPassword: {
      route: '/reset-password',
      generate: () => {
        return generate('/reset-password');
      },
    },
    completeRegistration: {
      route: '/complete-registration',
      generate: () => {
        return generate('/complete-registration');
      },
    },
    studentSurveys: {
      route: '/student-surveys',
      generate: (params: Record<string, string>) => {
        return generate(`/student-surveys${generateExtraQueryParams(params)}`);
      },
    },
    pathways: {
      route: '/pathways',
      generate: (id, version) => {
        if (id) {
          return generate(`/pathways/${id}${version && version !== 3 ? `/v${version}` : ''}`);
        }

        return generate('/pathways');
      },
    },
    organisationManagement: {
      route: '/organisation-management',
      generate: () => {
        return generate('/organisation-management');
      },
    },
    pathwaysHistory: {
      route: '/pathways/history',
      generate: () => {
        return generate('/pathways/history');
      },
    },
    userDetails: {
      route: '/user-details',
      generate: () => {
        return generate('/user-details');
      },
    },
    schoolDetails: {
      route: '/school-details',
      generate: () => {
        return generate('/school-details');
      },
    },
    userFeedback: {
      route: '/feedback',
      generate: () => {
        return generate('/feedback');
      },
    },
    schoolFeedback: {
      route: '/school-feedback',
      generate: () => {
        return generate('/school-feedback');
      },
    },
    elementsFeedback: {
      route: '/element-feedback',
      secondaryRoute: '/element-feedback/:id',
      generate: (id) => {
        if (id) {
          return generate('/element-feedback/:id?', { id });
        }

        return generate('/element-feedback');
      },
    },
    baselineFeedBackHistorical: {
      route: '/baseline-feedback-historical',
      generate: () => {
        return generate('/baseline-feedback-historical');
      },
    },
    elementsFeedbackHistorical: {
      route: '/element-feedback-historical',
      generate: () => {
        return generate('/element-feedback-historical');
      },
    },
    notificationManagement: {
      route: '/notification-management',
      generate: () => {
        return generate('/notification-management');
      },
    },
    eventsManagement: {
      route: '/events-management',
      generate: () => {
        return generate('/events-management');
      },
    },
    videoObservation: {
      route: '/video-observation',
      generate: () => {
        return generate('/video-observation');
      },
    },
    videoPlayer: {
      route: '/video-observation/:id',
      generate: (id) => {
        return generate('/video-observation/:id', { id });
      },
    },
    schoolManagement: {
      route: '/school-management',
      generate: () => {
        return generate('/school-management');
      },
    },
    cpdPassport: {
      route: '/cpd-passport',
      generate: () => {
        return generate('/cpd-passport');
      },
    },
    schoolEnv: {
      route: '/school-env',
      generate: () => {
        return generate('/school-env');
      },
    },
    webinarManagementPage: {
      route: '/zoom-management-page',
      generate: () => {
        return generate('/zoom-management-page');
      },
    },
    greatTeachingInset: {
      route: '/great-teaching-inset',
      generate: () => {
        return generate('/great-teaching-inset');
      },
    },
    communityDashboard: {
      route: 'community-dashboard',
      generate: () => {
        return generate('/community-dashboard');
      },
    },
    greatTeachingTeams: {
      route: '/great-teaching-teams',
      generate: () => {
        return generate('/great-teaching-teams');
      },
    },
    greatTeachingTeamsWorkspace: {
      route: '/great-teaching-teams/:id',
      generate: (id: string) => {
        return generate('/great-teaching-teams/:id', { id });
      },
    },
    mentoringTeams: {
      route: '/mentoring-teams',
      generate: () => {
        return generate('/mentoring-teams');
      },
    },
    mentoringTeamsWorkspace: {
      route: '/mentoring-teams/:id',
      generate: (id: string) => {
        return generate('/mentoring-teams/:id', { id });
      },
    },
    monitoring: {
      route: '/monitoring',
      generate: () => {
        return generate('/monitoring');
      },
    },
    resourcePage: {
      route: 'resource-page',
      generate: () => {
        return generate('/resource-page');
      },
    },
    resourceDashboard: {
      route: 'resource-dashboard',
      generate: () => {
        return generate('/resource-dashboard');
      },
    },
    modelForGreatTeaching: {
      route: '/model-for-great-teaching',
      generate: () => {
        return generate('/model-for-great-teaching');
      },
    },
    developmentActivities: {
      route: '/development-activities',
      generate: () => {
        return generate('/development-activities');
      },
    },
    downloadInfoPack: {
      route: '/download-info-pack',
      generate: () => {
        return generate('/download-info-pack');
      },
    },
  };

  return routes[path];
};

export type PathType =
  | 'login'
  | 'logout'
  | 'home'
  | 'register'
  | 'registerConfirmation'
  | 'survey'
  | 'classManagement'
  | 'userManagement'
  | 'groupManagement'
  | 'resetPassword'
  | 'studentSurveys'
  | 'pathways'
  | 'pathwaysHistory'
  | 'userDetails'
  | 'schoolDetails'
  | 'userFeedback'
  | 'schoolFeedback'
  | 'elementsFeedback'
  | 'baselineFeedBackHistorical'
  | 'elementsFeedbackHistorical'
  | 'notificationManagement'
  | 'organisationManagement'
  | 'videoObservation'
  | 'videoPlayer'
  | 'cpdPassport'
  | 'schoolEnv'
  | 'schoolManagement'
  | 'webinarManagementPage'
  | 'greatTeachingInset'
  | 'communityDashboard'
  | 'resourcePage'
  | 'resourceDashboard'
  | 'modelForGreatTeaching'
  | 'greatTeachingTeams'
  | 'greatTeachingTeamsWorkspace'
  | 'mentoringTeams'
  | 'mentoringTeamsWorkspace'
  | 'monitoring'
  | 'completeRegistration'
  | 'developmentActivities'
  | 'eventsManagement'
  | 'downloadInfoPack';

type RouteType = {
  [key in PathType]: RouteBuilderType;
};

type RouteBuilderType = {
  route: string;
  secondaryRoute?: string;
  generate: (param?: any, secondaryParams?: any) => string;
};

const generateExtraQueryParams = (params = {}) => {
  return Object.entries(params).reduce((acc, [key, value], index, array) => {
    return `${acc}${key}=${value}${array.length - 1 !== index ? '&' : ''}`;
  }, '?');
};

export default routeBuilder;
