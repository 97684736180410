import connector from '../connector';
import { CycleProps, StrategyProps } from '../../pages/pathway/pathwaysPage.types';
import { ResourceType } from '../../pages/pathway/components/addResources';
import {
  STUDENT_SURVEY_REFERENCE_TYPE,
  VIDEO_OBSERVATION_REFERENCE_TYPE,
} from '../../pages/pathway/modals/AddReferenceModal';
import { ReferenceType } from '../../pages/pathway/components/AddReference';
import { V3Pathway } from '../../@types/Entity/V3Pathway';
import { Strategy } from '../../@types/Entity/Strategy';
import { Resource } from '../../@types/Entity/Resource';
import { Goal } from '../../@types/Entity/Goal';
import { ActionTag } from '../../@types/Entity/ActionTag';

export function getV2Pathways(id: string) {
  type V2PathwaysType = {
    data: {
      id: string;
      steps: Record<string, { start_date: string; completed_at?: string }>;
      completed_at?: string;
      academic_year: string;
      focus_area?: string;
      team?: { id: string; name: string };
      current_step: string;
      number?: number;
    };
  };

  if (!id) {
    return connector.get<V2PathwaysType>('/api/development-pathway/v2/steps');
  }

  return connector.get<V2PathwaysType>(`/api/development-pathway/v2/${id}/steps`);
}

export function getV2PathwaysStep(step: string, id: string) {
  type V2PathwaysStepType = {
    data: {
      id: string;
      notes?: string;
      resources: Array<ResourceType>;
      references: Array<ReferenceType>;
      //Identify step
      focus?: {
        type: string;
        element: string;
      };
      //Explore step
      courses?: Array<{
        label: string;
        value: string;
        link: string;
      }>;
      //Focus step
      challenge?: string;
      //Prepare step
      strategies?: Array<StrategyProps>;
      //Integrate step
      school_classes?: Array<{
        id: string;
        name: string;
      }>;
      plans?: Array<CycleProps>;
      //Review step
      strategy_feedback?: string;
      challenge_feedback?: string;
    };
  };

  return connector.get<V2PathwaysStepType>(`/api/development-pathway/v2/${id}/${step}`);
}

export function getSharedResources(step: string, teamId: string) {
  type SharedResourcesType = {
    data: Array<{ label: string; value: string }>;
  };

  return connector.get<SharedResourcesType>(`api/development-pathway/v2/resources/${step}/shared-options/${teamId}`);
}

export function downloadResource(id: string, name: string) {
  return connector
    .get(`api/development-pathway/v2/resources/${id}/download`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export function getFocusOptions() {
  return connector.get('api/development-pathway/v2/focus-options');
}

export function completePathways() {
  return connector.put('api/development-pathway/v2/complete');
}

export function handleV2PathwaysStrategy(data: any) {
  if (data.id) {
    return connector.put('api/development-pathway/v2/strategy', data);
  }

  return connector.post('api/development-pathway/v2/strategy', data);
}

export function getAllV2Pathways(academicYear?: string, version?: string) {
  return connector.get('api/development-pathway/v2/pathways', {
    params: {
      academic_year: academicYear,
      version: version,
    },
  });
}

export function getDevelopmentActivities(academicYear?: string, pathways?: Array<string>, includeWithout?: boolean) {
  return connector.get('api/development-pathway/v2/activities', {
    params: {
      academic_year: academicYear,
      pathways: pathways,
      include_without: includeWithout,
    },
  });
}

export function getReferenceOptions(
  option: typeof STUDENT_SURVEY_REFERENCE_TYPE | typeof VIDEO_OBSERVATION_REFERENCE_TYPE
) {
  return connector.get<{
    data: Array<{ label: string; value: string; element?: Array<{ label: string; value: string }> }>;
  }>(
    `api/development-pathway/v2/references/options/${
      option === VIDEO_OBSERVATION_REFERENCE_TYPE ? 'videos' : 'student-surveys'
    }`
  );
}

export function getV3Pathways(params: { archived?: number }) {
  return connector.get<{ data: V3Pathway[] }>('api/development-pathway/v3/pathways', { params });
}

export function getV3Pathway(id: string) {
  return connector.get<{ data: V3Pathway }>(`api/development-pathway/v3/pathways/${id}`);
}

export function postOrPutV3Pathway(data: { id?: string; title: string; focus_element?: string; focus_type?: string }) {
  if (data.id) {
    return connector.put(`api/development-pathway/v3/pathways/${data.id}`, data);
  }

  return connector.post('api/development-pathway/v3/pathways', data);
}

export function putV3PathwayStatus(data: {
  id: string;
  status: 'complete' | 'start' | 'archive' | 'unarchive' | 'accept';
}) {
  return connector.put(`api/development-pathway/v3/pathways/${data.id}/${data.status}`);
}

export function deleteV3Pathway(id: string) {
  return connector.delete(`api/development-pathway/v3/pathways/${id}`);
}

export function addUpdateV3PathwayGoal(data: {
  id?: string;
  name?: string;
  description?: string;
  number?: number;
  status?: string;
  pathwayId?: string;
}) {
  const pathwayId = data.pathwayId;
  delete data.pathwayId;

  if (data.id) {
    return connector.patch(`api/development-pathway/v3/pathways/${pathwayId}/goals/${data.id}`, data);
  }

  return connector.post(`api/development-pathway/v3/pathways/${pathwayId}/goals`, data);
}

export function deleteV3PathwayGoal(data: { pathwayId: string; goalId: string }) {
  return connector.delete(`api/development-pathway/v3/pathways/${data.pathwayId}/goals/${data.goalId}`);
}

export function handleV3PathwaysStrategy(data: {
  pathwayId?: string;
  id?: string;
  name?: string;
  description?: string;
  steps?: string[];
  urls?: { id?: string; name?: string; link?: string }[];
  files?: File[];
  remove_resources?: string[];
  template_files?: string[];
}) {
  const formData = new FormData();

  if (data.name) {
    formData.append('name', data.name);
  }

  if (data.description) {
    formData.append('description', data.description);
  }

  if (data.steps) {
    formData.append('steps', JSON.stringify(data.steps));
  }

  if (data.urls) {
    formData.append('urls', JSON.stringify(data.urls));
  }

  if (data.files) {
    for (const file of data.files) {
      formData.append('files[]', file);
    }
  }

  if (data.remove_resources) {
    formData.append('removed_resources', JSON.stringify(data.remove_resources));
  }

  if (data.template_files) {
    formData.append('template_files', JSON.stringify(data.template_files));
  }

  if (data.id) {
    return connector.post(`api/development-pathway/v3/pathways/${data.pathwayId}/strategies/${data.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  return connector.post(`api/development-pathway/v3/pathways/${data.pathwayId}/strategies`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function deleteV3PathwayStrategy(data: { pathwayId: string; strategyId: string }) {
  return connector.delete(`api/development-pathway/v3/pathways/${data.pathwayId}/strategies/${data.strategyId}`);
}

export function shareV3PathwayStrategy(data: { pathwayId: string; strategyId: string }) {
  return connector.put(`api/development-pathway/v3/pathways/${data.pathwayId}/strategies/${data.strategyId}/share`);
}

export function getV3PathwayStrategiesTemplates(id: string, element?: string) {
  return connector.get<{
    data: Strategy[];
  }>(`api/development-pathway/v3/pathways/${id}/strategies`, { params: { element } });
}

export function addV3PathwayResource(data: {
  pathwayId: string;
  type: string;
  id?: string | number | null;
  name?: string | null;
  url?: string | null;
  file?: any;
}) {
  const formData = new FormData();
  formData.append('type', data.type);

  if (data.file) {
    formData.append('file', data.file);
  }

  if (data.id) {
    formData.append('id', data.id as string);
  }

  if (data.name) {
    formData.append('name', data.name);
  }

  if (data.url) {
    formData.append('url', data.url);
  }

  return connector.post(`api/development-pathway/v3/pathways/${data.pathwayId}/resources`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getV3PathwaySharedResources(pathwayId: string) {
  return connector.get<{
    data: Resource[];
  }>(`api/development-pathway/v3/pathways/${pathwayId}/resources/shared`);
}

export function shareV3PathwayResource(data: { pathwayId: string; resourceId: string; share: boolean }) {
  return connector.put(
    `api/development-pathway/v3/pathways/${data.pathwayId}/resources/${data.resourceId}/${
      data.share ? 'share' : 'unshare'
    }`
  );
}

export function removeV3PathwayResource(data: { pathwayId: string; resourceId: string }) {
  return connector.delete(`api/development-pathway/v3/pathways/${data.pathwayId}/resources/${data.resourceId}`);
}

export function downloadV3PathwayResource(pathwayId: string, resourceId: string, name: string) {
  return connector
    .get(`api/development-pathway/v3/pathways/${pathwayId}/resources/${resourceId}/download`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export function handleV3PathwayLog(data: {
  pathwayId: string;
  id?: string;
  title?: string;
  notes?: string;
  file?: File | null;
  video?: number;
  isc?: number;
  element?: string;
}) {
  if (data.id) {
    return connector.put(`api/development-pathway/v3/pathways/${data.pathwayId}/logs/${data.id}`, {
      title: data.title,
      notes: data.notes,
    });
  }

  const formData = new FormData();

  if (data.title) {
    formData.append('title', data.title);
  }

  if (data.notes) {
    formData.append('notes', data.notes);
  }

  if (data.file) {
    formData.append('file', data.file);
  }

  if (data.video) {
    formData.append('video', data.video.toString());
  }

  if (data.isc) {
    formData.append('isc', data.isc.toString());
  }

  if (data.element) {
    formData.append('element', data.element);
  }

  return connector.post(`api/development-pathway/v3/pathways/${data.pathwayId}/logs`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function removeV3PathwayLog(data: { pathwayId: string; logId: string }) {
  return connector.delete(`api/development-pathway/v3/pathways/${data.pathwayId}/logs/${data.logId}`);
}

export function handleV3PathwayActions(data: {
  pathwayId: string;
  actions: {
    id: string | null;
    title: string;
    tags: { id: string | null; title: string }[];
    dueDate: string;
  }[];
}) {
  return connector.post(`api/development-pathway/v3/pathways/${data.pathwayId}/actions`, { actions: data.actions });
}

export function getV3PathwayActionTags(pathwayId: string) {
  return connector.get<{ data: ActionTag[] }>(`api/development-pathway/v3/pathways/${pathwayId}/tags`);
}

export function completeV3PathwayAction(data: { pathwayId: string; actionId: string }) {
  return connector.put(`api/development-pathway/v3/pathways/${data.pathwayId}/actions/${data.actionId}/complete`);
}

export function getV3PathwayGoalsTemplates(id: string) {
  return connector.get<{
    data: Goal[];
  }>(`api/development-pathway/v3/pathways/${id}/goals`);
}
