import React, { useEffect, useRef, useState } from 'react';
import RadarChartSection, { ALL_OPTION, processedRadarData } from '../sections/radarChartSection';
import SummaryChartSection, { processedSummaryData } from '../sections/summaryChartSection';
import DimensionsChartSection, { processedDimensionData } from '../sections/dimensionsChartSection';
import { Document, Page, pdf, Text, View } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Button from '../../../global/button/button';
import DashboardLayout from '../../../layout/dashboard';
import { Section } from '../../common';
import PDFLayout, { MARGIN_LEFT_TEXT, MARGIN_TOP_PAGE, PDF_STYLES } from '../../../layout/pdf';
import FeedbackLayout from '../components/feedbackLayout';
import { VERSION_B, VERSION_C, VERSION_C_D, VERSION_D, VERSION_E } from '../../instrument/sections/instrumentForm';
import TimeFrameTable, {
  TIMEFRAME_NO_OPTION,
  TimeframeOptionType,
  TimeframeSubOptionType,
} from '../sections/TimeFrameTable';
import ElementChartSection, { processedElementData } from '../sections/elementChartSection';
import {
  getAcademicYears,
  getDimensionsFeedbackData,
  getElementsFeedbackData,
  getMe,
  getRadarFeedbackData,
  getSummaryFeedbackData,
} from '../../../service/api';
import PDFChart from '../PDFChart';
import CustomRadarChart, { CustomRadarChartPDF } from '../components/customRadarChart';
import CustomBarChart, { CustomBarChartPDF } from '../components/customBarChart';
import { DIMENSIONS_NAMES } from '../../../resources/dimensions';
import Tippy from '@tippyjs/react';
import SideTab from '../../../typeform/SideTab';
import { useQuery } from 'react-query';
import { ACADEMIC_YEARS, ME } from '../../../service/queryKeys';
import { ROLE_TEACHER_FREE } from '../../../resources/roles';
import Accordion from '../../../global/accordion/accordion';
import {
  downloadGttProfileDimensionData,
  gttProfileBarData,
  gttProfileDimensionData,
  gttProfileRadarData,
} from '../../../resources/starterTemplateData';
import { LinkStyled } from '../../../global/link/link.styled';
import routeBuilder from '../../../service/routeBuilder';
import VideoAlert from '../../../guides/components/videoAlert';

const groupDifferentDimensions = (optionsDimensions: Array<{ id: string; name: string; dimension?: number }>) => {
  const dimensionGroups: Record<number, Array<{ id: string; name: string; dimension?: number }>> = {};
  dimensionGroups[Math.random()] = [];

  let found;
  for (const dimensionOption of optionsDimensions) {
    found = false;
    for (const group of Object.values(dimensionGroups)) {
      if (group.some((g) => dimensionOption.dimension === g.dimension)) {
        continue;
      }

      group.push(dimensionOption);
      found = true;
    }

    if (!found) {
      dimensionGroups[Math.random()] = [dimensionOption];
    }
  }

  return dimensionGroups;
};

const UserFeedbackView = () => {
  const [loading, setLoading] = useState(false);
  const pdfRadarLegend = useRef([]);

  const [activeVersion, setActiveVersion] = useState<string>();

  // Timeframe
  const [timeframeOption, setTimeFrameOption] = useState<TimeframeOptionType>(TIMEFRAME_NO_OPTION);
  const [timeframeSelections, setTimeFrameOptionSelections] = useState<Array<TimeframeSubOptionType>>([]);

  const me = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const isFree = me?.data?.role === ROLE_TEACHER_FREE;

  const [accordionActive, setAccordionActive] = useState(0);

  const [hide, setHide] = useState<boolean | string>('undefined');

  const [view, setView] = useState<'initial' | 'real' | 'example'>('initial');

  const academicYearsQuery = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    staleTime: Infinity,
    select: (data) => data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  useEffect(() => {
    if (timeframeSelections.some((s) => s.survey_version === VERSION_B)) {
      setActiveVersion(VERSION_B);
      return;
    }

    if (timeframeSelections.some((s) => s.survey_version === VERSION_C || s.survey_version === VERSION_D)) {
      setActiveVersion(VERSION_C_D);
      return;
    }

    if (timeframeSelections.some((s) => s.survey_version === VERSION_E)) {
      setActiveVersion(VERSION_E);
      return;
    }

    setActiveVersion(undefined);
  }, [timeframeSelections]);

  const getFilteredIds = (element?: string) => {
    let filteredSelections: Array<TimeframeSubOptionType> = [];

    if (element) {
      filteredSelections = timeframeSelections.filter(
        (s) => s.survey_type?.type === 'element' && s.survey_type.id === element
      );
    } else {
      filteredSelections = timeframeSelections.filter((s) => {
        if (activeVersion === VERSION_B) {
          return s.survey_type?.type !== 'element' && s.survey_version === VERSION_B;
        }

        if (activeVersion === VERSION_C_D) {
          return (
            s.survey_type?.type !== 'element' && (s.survey_version === VERSION_C || s.survey_version === VERSION_D)
          );
        }

        if (activeVersion === VERSION_E) {
          return s.survey_type?.type !== 'element' && s.survey_version === VERSION_E;
        }

        return s.survey_type?.type !== 'element';
      });
    }

    return filteredSelections.map((s) => s.id);
  };

  const testDownload = async () => {
    setLoading(true);

    try {
      const blob = await pdf(
        <Document>
          <Page size="A4" style={{ ...PDF_STYLES.page }} key={Math.random()}>
            <PDFLayout.Header/>
            <Text
              style={{
                ...PDF_STYLES.headline,
                marginLeft: MARGIN_LEFT_TEXT,
                marginTop: 0,
              }}
            >
              Element 4.3 Demo Data
            </Text>
            <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
              <PDFChart>
                <CustomRadarChartPDF id={`radar_starterTemplate`} data={gttProfileRadarData} width={225} height={225}/>
              </PDFChart>
            </View>
            <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
              Elements of Great Teaching
            </Text>
            <PDFChart>
              <CustomBarChartPDF
                data={gttProfileBarData}
                dataKey={'element'}
                id={`summary_starterTemplate`}
                height={450}
              />
            </PDFChart>
          </Page>
          <Page size="A4" style={{ ...PDF_STYLES.page }} key={Math.random()}>
            <View key={Math.random()} wrap={false}>
              <Text
                style={{
                  ...PDF_STYLES.headline,
                  marginLeft: MARGIN_LEFT_TEXT,
                  marginTop: MARGIN_TOP_PAGE,
                  marginBottom: '6px',
                }}
              >
                4.3 Questioning
              </Text>
              <PDFChart>
                <CustomBarChartPDF
                  data={downloadGttProfileDimensionData}
                  dataKey={'question'}
                  id={`dimensionChartId-starterTemplate`}
                  height={380}
                />
              </PDFChart>
            </View>
          </Page>
        </Document>
      ).toBlob();

      saveAs(blob, 'survey-feedback');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    setLoading(true);

    const bTimeframeIds = timeframeSelections
      .filter((s) => s.survey_type?.type !== 'element' && s.survey_version === VERSION_B)
      .map((s) => s.id);
    const cdTimeframeIds = timeframeSelections
      .filter(
        (s) => s.survey_type?.type !== 'element' && (s.survey_version === VERSION_C || s.survey_version === VERSION_D)
      )
      .map((s) => s.id);
    const eTimeframeIds = timeframeSelections
      .filter((s) => s.survey_type?.type !== 'element' && s.survey_version === VERSION_E)
      .map((s) => s.id);

    const elementTimeframe = timeframeSelections.reduce<Record<string, any>>((acc, curr) => {
      if (curr.survey_type?.type !== 'element') {
        return acc;
      }

      (acc[curr.survey_type.id!] = acc[curr.survey_type.id!] || []).push(curr.id);
      return acc;
    }, {});

    try {
      const chartsData = await Promise.all([
        bTimeframeIds.length > 0 ? getRadarFeedbackData(bTimeframeIds, undefined, VERSION_B) : null,
        bTimeframeIds.length > 0 ? getSummaryFeedbackData(bTimeframeIds, undefined, VERSION_B, undefined, true) : null,
        bTimeframeIds.length > 0 ? getDimensionsFeedbackData(bTimeframeIds, VERSION_B) : null,
        cdTimeframeIds.length > 0 ? getRadarFeedbackData(cdTimeframeIds, undefined, VERSION_C_D) : null,
        cdTimeframeIds.length > 0
          ? getSummaryFeedbackData(cdTimeframeIds, undefined, VERSION_C_D, undefined, true)
          : null,
        cdTimeframeIds.length > 0 ? getDimensionsFeedbackData(cdTimeframeIds, VERSION_C_D) : null,
        eTimeframeIds.length > 0 ? getRadarFeedbackData(eTimeframeIds, undefined, VERSION_E) : null,
        eTimeframeIds.length > 0 ? getSummaryFeedbackData(eTimeframeIds, undefined, VERSION_E, undefined, true) : null,
        eTimeframeIds.length > 0 ? getDimensionsFeedbackData(eTimeframeIds, VERSION_E) : null,
        Promise.all(
          Object.entries(elementTimeframe).map(([element, timeFrameIds]) =>
            getElementsFeedbackData(element, timeFrameIds, undefined, true)
          )
        ),
      ]);

      const bOptions = Object.values(
        chartsData[0]?.data.data.options.reduce<
          Record<string, Array<{ id: string; name: string; dimension?: number }>>
        >((acc, curr) => {
          if (curr.id === ALL_OPTION || curr.dimension != null) {
            return acc;
          }

          acc[curr.id] = [curr];
          return acc;
        }, {}) ?? {}
      );

      const bOptionsDimensions = chartsData[0]?.data.data.options.filter((option) => option.dimension != null) ?? [];
      if (bOptionsDimensions.length > 0) {
        const dimensionGroups = groupDifferentDimensions(bOptionsDimensions);
        for (const dimensionGroup of Object.values(dimensionGroups)) {
          bOptions.push(dimensionGroup.sort((a, b) => a.dimension! - b.dimension!));
        }
      }

      const cdOptions = Object.values(
        chartsData[3]?.data.data.options.reduce<
          Record<string, Array<{ id: string; name: string; dimension?: number }>>
        >((acc, curr) => {
          if (curr.id === ALL_OPTION || curr.dimension != null) {
            return acc;
          }

          acc[curr.id] = [curr];
          return acc;
        }, {}) ?? {}
      );

      const cdOptionsDimensions = chartsData[3]?.data.data.options.filter((option) => option.dimension != null) ?? [];
      if (cdOptionsDimensions.length > 0) {
        const dimensionGroups = groupDifferentDimensions(cdOptionsDimensions);
        for (const dimensionGroup of Object.values(dimensionGroups)) {
          cdOptions.push(dimensionGroup.sort((a, b) => a.dimension! - b.dimension!));
        }
      }

      const eOptions = Object.values(
        chartsData[6]?.data.data.options.reduce<
          Record<string, Array<{ id: string; name: string; dimension?: number }>>
        >((acc, curr) => {
          if (curr.id === ALL_OPTION || curr.dimension != null) {
            return acc;
          }

          acc[curr.id] = [curr];
          return acc;
        }, {}) ?? {}
      );

      const eOptionsDimensions = chartsData[6]?.data.data.options.filter((option) => option.dimension != null) ?? [];
      if (eOptionsDimensions.length > 0) {
        const dimensionGroups = groupDifferentDimensions(eOptionsDimensions);
        for (const dimensionGroup of Object.values(dimensionGroups)) {
          eOptions.push(dimensionGroup.sort((a, b) => a.dimension! - b.dimension!));
        }
      }

      let page = 0;

      const blob = await pdf(
        <Document>
          {bOptions.map((options) => {
            chartsData[0]!.data.data.data;

            if (
              Array.isArray(chartsData[0]!.data.data.data) ||
              chartsData[0]!.data.data.data[options[0].id].length === 0 ||
              Array.isArray(chartsData[1]!.data.data.data) ||
              chartsData[1]!.data.data.data[options[0].id] === null
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={{ ...PDF_STYLES.page }} key={Math.random()}>
                {page === 1 && <PDFLayout.Header/>}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {options.map((o) => o.name).join(' / ')}
                </Text>
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${options[0].id}`}
                      data={processedRadarData(
                        chartsData[0]!.data.data,
                        options.map((o) => o.id)
                      )}
                      width={page === 1 ? 225 : 300}
                      height={page === 1 ? 225 : 300}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(
                      chartsData[1]!.data.data,
                      options.map((o) => o.id),
                      VERSION_B
                    )}
                    dataKey={'element'}
                    id={`summary_${options[0].id}`}
                    height={options.length > 1 ? 425 : 450}
                  />
                </PDFChart>
                {Object.entries(chartsData[2]?.data.data.data ?? {})
                  .filter((dimension: Record<number, any>) => {
                    const values = Object.values(dimension[1]);
                    const optionIds = options.map((o) => o.id);

                    return (
                      values.length > 0 &&
                      values.some((value: any) => optionIds.some((optionId) => value[optionId] != null))
                    );
                  })
                  .map((dimension: Record<number, any>) => (
                    <View key={Math.random()} wrap={false}>
                      <Text
                        style={{
                          ...PDF_STYLES.headline,
                          marginLeft: MARGIN_LEFT_TEXT,
                          marginTop: MARGIN_TOP_PAGE,
                          marginBottom: '6px',
                        }}
                      >
                        Dimension {dimension[0]} — {DIMENSIONS_NAMES[dimension[0] as 1 | 2 | 3 | 4]}
                      </Text>
                      <PDFChart>
                        <CustomBarChartPDF
                          data={processedDimensionData(
                            chartsData[2]!.data.data,
                            dimension[0],
                            options.map((o) => o.id)
                          )}
                          dataKey={'question'}
                          id={`dimensionChartId-${dimension[0]}-`}
                          height={380}
                        />
                      </PDFChart>
                    </View>
                  ))}
              </Page>
            );
          })}
          {cdOptions.map((options) => {
            if (
              Array.isArray(chartsData[3]!.data.data.data) ||
              chartsData[3]!.data.data.data[options[0].id].length === 0 ||
              Array.isArray(chartsData[4]!.data.data.data) ||
              chartsData[4]!.data.data.data[options[0].id] === null
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={PDF_STYLES.page} key={Math.random()}>
                {page === 1 && <PDFLayout.Header/>}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {options.map((o) => o.name).join(' / ')}
                </Text>
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${options[0].id}`}
                      data={processedRadarData(
                        chartsData[3]!.data.data,
                        options.map((o) => o.id)
                      )}
                      width={page === 1 ? 225 : 300}
                      height={page === 1 ? 225 : 300}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(
                      chartsData[4]!.data.data,
                      options.map((o) => o.id),
                      VERSION_C_D
                    )}
                    dataKey={'element'}
                    id={`summary_${options[0].id}`}
                    height={options.length > 1 ? 425 : 450}
                  />
                </PDFChart>
                {Object.entries(chartsData[5]!.data.data.data)
                  .filter((dimension: Record<number, any>) => {
                    const values = Object.values(dimension[1]);
                    const optionIds = options.map((o) => o.id);

                    return (
                      values.length > 0 &&
                      values.some((value: any) => optionIds.some((optionId) => value[optionId] != null))
                    );
                  })
                  .map((dimension: Record<number, any>) => {
                    return Object.entries(dimension[1])
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((element: Record<number, any>, index) => (
                        <View key={Math.random()} wrap={false}>
                          {index === 0 && (
                            <Text
                              style={{
                                ...PDF_STYLES.headline,
                                marginLeft: MARGIN_LEFT_TEXT,
                                marginTop: MARGIN_TOP_PAGE,
                              }}
                            >
                              Dimension {dimension[0]} — {DIMENSIONS_NAMES[dimension[0] as 1 | 2 | 3 | 4]}
                            </Text>
                          )}
                          <Text
                            style={{
                              ...PDF_STYLES.headline,
                              marginLeft: MARGIN_LEFT_TEXT,
                              marginTop: index === 0 ? '6px' : MARGIN_TOP_PAGE,
                              marginBottom: '6px',
                            }}
                          >
                            {element[0]}
                          </Text>
                          <PDFChart>
                            <CustomBarChartPDF
                              data={processedDimensionData(
                                chartsData[5]!.data.data,
                                dimension[0],
                                options.map((o) => o.id),
                                element[0]
                              )}
                              dataKey={'question'}
                              id={`dimensionChartId-${dimension[0]}-${element[0]}`}
                              height={350}
                            />
                          </PDFChart>
                        </View>
                      ));
                  })}
              </Page>
            );
          })}
          {eOptions.map((options) => {
            if (
              Array.isArray(chartsData[6]!.data.data.data) ||
              chartsData[6]!.data.data.data[options[0].id].length === 0 ||
              Array.isArray(chartsData[7]!.data.data.data) ||
              chartsData[7]!.data.data.data[options[0].id] === null
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={PDF_STYLES.page} key={Math.random()}>
                {page === 1 && <PDFLayout.Header/>}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {options.map((o) => o.name).join(' / ')}
                </Text>
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${options[0].id}`}
                      data={processedRadarData(
                        chartsData[6]!.data.data,
                        options.map((o) => o.id)
                      )}
                      width={page === 1 ? 225 : 300}
                      height={page === 1 ? 225 : 300}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(
                      chartsData[7]!.data.data,
                      options.map((o) => o.id),
                      VERSION_E
                    )}
                    dataKey={'element'}
                    id={`summary_${options[0].id}`}
                    height={options.length > 1 ? 425 : 450}
                  />
                </PDFChart>
                {Object.entries(chartsData[8]!.data.data.data)
                  .filter((dimension: Record<number, any>) => {
                    const values = Object.values(dimension[1]);
                    const optionIds = options.map((o) => o.id);

                    return (
                      values.length > 0 &&
                      values.some((value: any) => optionIds.some((optionId) => value[optionId] != null))
                    );
                  })
                  .map((dimension: Record<number, any>) => {
                    return Object.entries(dimension[1])
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((element: Record<number, any>, index) => (
                        <View key={Math.random()} wrap={false}>
                          {index === 0 && (
                            <Text
                              style={{
                                ...PDF_STYLES.headline,
                                marginLeft: MARGIN_LEFT_TEXT,
                                marginTop: MARGIN_TOP_PAGE,
                              }}
                            >
                              Dimension {dimension[0]} — {DIMENSIONS_NAMES[dimension[0] as 1 | 2 | 3 | 4]}
                            </Text>
                          )}
                          <Text
                            style={{
                              ...PDF_STYLES.headline,
                              marginLeft: MARGIN_LEFT_TEXT,
                              marginTop: index === 0 ? '6px' : MARGIN_TOP_PAGE,
                              marginBottom: '6px',
                            }}
                          >
                            {element[0]}
                          </Text>
                          <PDFChart>
                            <CustomBarChartPDF
                              data={processedDimensionData(
                                chartsData[8]!.data.data,
                                dimension[0],
                                options.map((o) => o.id),
                                element[0]
                              )}
                              dataKey={'question'}
                              id={`dimensionChartId-${dimension[0]}-${element[0]}`}
                              height={350}
                            />
                          </PDFChart>
                        </View>
                      ));
                  })}
              </Page>
            );
          })}
          {Object.entries(elementTimeframe).map(([element], index) => {
            return chartsData[9][index].data.data.options.map((option) => {
              if (option.id === ALL_OPTION) {
                return;
              }

              page++;

              return (
                <Page size="A4" style={PDF_STYLES.page} orientation={'landscape'} key={Math.random()}>
                  {page === 1 && <PDFLayout.Header/>}
                  <View wrap={false}>
                    <Text
                      style={{
                        ...PDF_STYLES.headline,
                        marginLeft: MARGIN_LEFT_TEXT,
                        marginTop: MARGIN_TOP_PAGE,
                        marginBottom: '6px',
                      }}
                    >
                      {option.name} - {element}
                    </Text>
                    <PDFChart>
                      <CustomBarChartPDF
                        data={processedElementData(chartsData[9][index].data.data, element, [option.id])}
                        dataKey={'question'}
                        id={`elementChartId-${element}`}
                        width={850}
                        height={600}
                        landscape
                      />
                    </PDFChart>
                  </View>
                </Page>
              );
            });
          })}
        </Document>
      ).toBlob();

      saveAs(blob, 'survey-feedback');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout
      title={isFree ? 'Student feedback' : 'GTT Profile feedback page'}
      pageAction={
        <div className={isFree ? 'flex gap-2' : ''}>
          {isFree && (
            <div>
              <SideTab formId={'nFVnOLfm'}/>
              <LinkStyled href={routeBuilder('studentSurveys').generate()}>
                <Button isOutline>Generate own survey</Button>
              </LinkStyled>
            </div>
          )}
          <Tippy
            disabled={timeframeSelections.length > 0}
            content={
              <>{isFree ? 'Generate a downloadable PDF' : 'Select your survey(s) to generate a downloadable PDF'}</>
            }
            theme={'translucent'}
            interactive
            interactiveBorder={10}
            animation={'shift-away'}
            allowHTML
            appendTo={() => document.body}
          >
            <div>
              <SideTab formId={'nFVnOLfm'}/>
              <Button
                disabled={isFree ? false : loading || timeframeSelections.length === 0}
                onClick={isFree && (hide || timeframeSelections.length < 1) ? testDownload : handleDownload}
                addOutline={loading || timeframeSelections.length === 0}
              >
                {loading ? 'Loading...' : 'Download'}
              </Button>
            </div>
          </Tippy>
        </div>
      }
    >
      {isFree && (
        <div>
          <VideoAlert
            setVideo={undefined}
            showHideForever={false}
            showHide={false}
            starterTemplate={isFree}
            data={{
              title: '',
              id: 'starter_template_dev_cycles',
              description: (
                <>
                  <p className={'mb-4'}>
                    This teacher has used the student surveys to generate feedback on their teaching practice. The data
                    the teacher has received, which is completely confidential, has identified real strengths in
                    Dimension 4 overall, but suggests that Questioning could be a development area.
                  </p>
                  <p className={'mb-4'}>
                    The student surveys, accessible for reading ages 5-16+, can be used to suggest development areas,
                    identify pockets of expertise and measure progress.
                  </p>
                  <p>
                    Once five teachers have completed a survey, we provide anonymised, aggregated survey data, to
                    identify school-wide strengths and development areas.
                  </p>
                </>
              ),
            }}
          />
        </div>
      )}

      {!isFree || (isFree && hide != true) ? (
        <div className={isFree && hide === 'undefined' ? 'hidden' : ''}>
          <TimeFrameTable
            hide={setHide}
            setView={setView}
            view={view}
            isFree={isFree}
            option={timeframeOption}
            setOption={setTimeFrameOption}
            confirmSelections={setTimeFrameOptionSelections}
          />
        </div>
      ) : (
        <></>
      )}
      {(timeframeSelections.length > 0 && isFree && !hide && view === 'real') ||
      (!isFree && timeframeSelections.length > 0) ? (
        <FeedbackLayout
          showTabs={
            timeframeSelections.some((s) => s.survey_version === VERSION_B) &&
            timeframeSelections.some((s) => s.survey_version === VERSION_C || s.survey_version === VERSION_D)
          }
          items={[
            { value: VERSION_B, label: VERSION_B },
            { value: VERSION_C_D, label: VERSION_C_D },
          ]}
          active={activeVersion ?? null}
          clickOnTab={(value: string) => {
            setActiveVersion(value);
          }}
        >
          <>
            {timeframeSelections.some((s) => s.survey_type?.type !== 'element') && (
              <>
                <Section
                  headline={
                    <>
                      <p>{`GTT Profile ${timeframeOption.label}`}</p>
                      <p>{`${timeframeOption.start_date} - ${timeframeOption.end_date}`}</p>
                    </>
                  }
                  size={'md'}
                  className={'field-mb'}
                >
                  <RadarChartSection
                    pdfLegend={pdfRadarLegend}
                    surveyVersion={activeVersion}
                    timeframeSelections={getFilteredIds()}
                  />
                </Section>
                <Section headline={'Breakdown GTT Profile'} size={'md'} className={'field-mb'}>
                  <SummaryChartSection surveyVersion={activeVersion} timeframeSelections={getFilteredIds()}/>
                </Section>
                <Section headline={'Model of Great Teaching Dimensions'} size={'md'} className={'field-mb'}>
                  <DimensionsChartSection surveyVersion={activeVersion} timeframeSelections={getFilteredIds()}/>
                </Section>
              </>
            )}
            {activeVersion === VERSION_C_D &&
              timeframeSelections
                .reduce<Array<string>>((acc, curr) => {
                  if (curr.survey_type?.type !== 'element' || acc.includes(curr.survey_type.id!)) {
                    return acc;
                  }

                  acc.push(curr.survey_type.id!);
                  return acc;
                }, [])
                .map((element) => (
                  <ElementChartSection
                    key={Math.random()}
                    timeframeSelections={getFilteredIds(element)}
                    element={element}
                  />
                ))}
          </>
        </FeedbackLayout>
      ) : (
        <></>
      )}
      {(timeframeSelections.length < 1 && isFree) || (isFree && ['initial', 'example'].includes(view)) ? (
        <>
          <Section
            headline={
              <>
                <p>{`GTT Profile ${isFree ? academicYearsQuery?.data[0]?.name ?? '' : timeframeOption.label}`}</p>
                {isFree ? (
                  academicYearsQuery?.data[0]?.startDate && academicYearsQuery?.data[0]?.endDate ? (
                    <p>
                      {academicYearsQuery?.data[0]?.startDate} - {academicYearsQuery?.data[0]?.endDate}
                    </p>
                  ) : (
                    <></>
                  )
                ) : (
                  <p>
                    {timeframeOption.start_date} - {timeframeOption.end_date}
                  </p>
                )}
              </>
            }
            size={'md'}
            className={'field-mb'}
          >
            <CustomRadarChart data={gttProfileRadarData} id={'emulatedStarterChart'}/>
          </Section>
          <Section headline={'Breakdown GTT Profile'} size={'md'} className={'field-mb'}>
            <CustomBarChart
              data={gttProfileBarData}
              dataKey={'element'}
              starterTemplate
              id={'emulatedSummaryChartId'}
            />
          </Section>
          <Section headline={'Model of Great Teaching Dimensions'} size={'md'} className={'field-mb'}>
            {Object.entries(gttProfileDimensionData.data)
              .filter((dimension: Record<number, any>) => Object.values(dimension[1]).length > 0)
              .map((dimension: Record<number, any>, index: number) => (
                <div key={`dimension_chart--${index}`}>
                  <Accordion
                    title={
                      <div>
                        <span className={'text-muted'}>Dimension {dimension[0]} —</span>
                        <b>{DIMENSIONS_NAMES[dimension[0] as 1 | 2 | 3 | 4]}</b>
                      </div>
                    }
                    isOpen={index === accordionActive}
                    handleOpen={() => setAccordionActive(index)}
                  >
                    {Object.entries(dimension[1])
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map(
                        (element: Record<number, any>, index) =>
                          element[0] === '4.3' && (
                            <div key={`element--${index}`} className={'overflow-x-auto field-mb'}>
                              <h5 className={'field-mb'}>{element[0]}</h5>
                              <CustomBarChart
                                starterTemplate={true}
                                data={processedDimensionData(gttProfileDimensionData, dimension[0], [7897], element[0])}
                                dataKey={'question'}
                                id={`dimensionChartId-${dimension[0]}-${element[0]}`}
                              />
                            </div>
                          )
                      )}
                  </Accordion>
                </div>
              ))}
          </Section>
        </>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};

export default UserFeedbackView;
