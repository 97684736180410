import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { Section } from '../common';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from '../../global/tab/tab';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { VIDEO, VIDEO_TAGS } from '../../service/queryKeys';
import { getTagSet, getVideo, postFeedbackSession } from '../../service/api';
import Button from '../../global/button/button';
import CommentsTab from './sections/CommentsTab';
import FeedbackTab from './sections/FeedbackTab';
import classnames from 'classnames';
import TagIcon, { TagType, TEMP_ID_PREFIX } from './components/TagIcon';
import TagOnVideo from './components/TagOnVideo';
import routeBuilder from '../../service/routeBuilder';
import useModal from '../../hooks/useModal';
import ShareVideoModal from './modals/ShareVideoModal';
import TagSetsModal from './modals/TagSetsModal';
import Accordion from '../../global/accordion/accordion';
import TagSet from './components/TagSet';
import AddTagCommentModal from './modals/AddTagCommentModal';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';
import Tooltip from '../../global/tooltip/tooltip';
import EditTitleModal from './modals/EditTitleModal';
import DeleteVideoModal from './modals/DeleteVideoModal';
import { ButtonIconStyled } from '../../global/buttonIcon/buttonIcon.styled';
import Icon from '../../global/icon/icon';
import { GLOBAL_ICONS, SIZE_ICON } from '../../resources/vars';
import VideoAlert from '../../guides/components/videoAlert';
import { starterTemplateVideoFeedbackData } from '../../resources/starterTemplateData';

const FEEDBACK_TAB = 1;
const COMMENTS_TAB = 2;

export const TABS = [
  {
    value: FEEDBACK_TAB,
    label: 'Show feedback',
  },
  {
    value: COMMENTS_TAB,
    label: 'Feedback comments',
  },
];

const VideoPlayerPage = ({ userId, isFree }: { userId: string; isFree?: boolean }) => {
  const { id } = useParams();
  const [selectedTag, setSelectedTag] = useState<TagType | null>();
  const [feedBackTags, setFeedbackTags] = useState<FeedbackElement[] | null>(
    isFree ? starterTemplateVideoFeedbackData : null
  );
  const [tagSetId, setTagSetId] = useState<number | null>(isFree ? 916 : null);
  const [feedbackId, setFeedbackId] = useState<number | null>();
  const [elementAccordion, setElementAccordion] = useState<number | null>();
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);

  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(FEEDBACK_TAB);

  const [sessionTags, setSessionTags] = useState<TagType[]>([]);

  const { data: video } = useQuery([VIDEO, id], () => getVideo(id!), {
    select: (data) => data.data.data,
    onError: () => {
      navigate(routeBuilder('videoObservation').generate());
    },
  });

  const isVideoOwner = video?.created_by.id === userId;

  const queryClient = useQueryClient();
  const feedBackSessionMutation = useMutation(postFeedbackSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(VIDEO_TAGS);
      queryClient.invalidateQueries([VIDEO, id]);
      toast.success('The feedback session has been saved successfully.');

      cleanStates();
      setActiveTab(FEEDBACK_TAB);
    },
    onError: () => {
      toast.error('An error has occurred trying to save the feedback session.');
    },
  });

  useEffect(() => {
    if (video && feedBackTags) {
      setActiveTab(COMMENTS_TAB);
    }
  }, [video, feedBackTags]);

  const [shareVideoModal, toggleShareVideoModal] = useModal(
    (props: any) => <ShareVideoModal {...props} videoId={id} />,
    {
      title: 'Share video',
    }
  );

  const [tagSetsModal, toggleTagSetsModal] = useModal(
    (props: any) => <TagSetsModal {...props} setTagSetId={setTagSetId} setTags={setFeedbackTags} />,
    {
      title: 'Tag sets',
    }
  );

  const [editedTitle, setEditedTitle] = useState<string | null>();
  const [editTitleModal, toggleEditTitleModal, setEditTitleModalProps] = useModal(
    (props: any) => <EditTitleModal {...props} setTitle={setEditedTitle} />,
    {
      title: 'Edit title',
    }
  );

  const [deleteVideoModal, toggleDeleteVideoModal] = useModal((props: any) => <DeleteVideoModal {...props} />, {
    title: 'Delete video',
    videoId: id,
  });

  const [tagCommentModal, toggleTagCommentModal, setTagCommentModalProps] = useModal((props: any) => (
    <AddTagCommentModal
      {...props}
      addComment={(id: number | string, comment: string) => {
        const tagFound = sessionTags.find((tag) => tag.id === id)!;

        setSessionTags((prevState) => [
          ...prevState.filter((tag) => tag.id !== id),
          {
            ...tagFound,
            comment: comment,
          },
        ]);
      }}
    />
  ));

  const addTagToFeedBackSession = (element: FeedbackElement, tag: FeedbackTag, icon: string) => {
    setSessionTags((prevState) => {
      return [
        ...prevState,
        {
          id: `${TEMP_ID_PREFIX}${uuidV4()}`,
          tag_id: element.id,
          subtag_id: tag.id,
          name: tag.name,
          time: (videoRef as MutableRefObject<any>).current.currentTime,
          icon: {
            name: icon,
            colour: element.colour,
          },
        },
      ];
    });
  };

  const cleanStates = () => {
    setFeedbackTags(null);
    setFeedbackId(null);
    setElementAccordion(null);
    setTagSetId(null);
    setSessionTags([]);
    setEditedTitle(null);
  };

  return (
    <DashboardLayout title={video?.title} hideTitle parent={{ id: 'videoObservation', label: 'Video feedback' }}>
      {video ? (
        <>
          {isFree && (
            <div>
              <VideoAlert
                setVideo={undefined}
                showHideForever={false}
                showHide={false}
                starterTemplate={isFree}
                data={{
                  title: '',
                  id: 'starter_template_video_feedback',
                  description: (
                    <>
                      <p className={'mb-4'}>
                        Here is a video of a teacher integrating new hinge questions into their class. As part of their
                        coaching, this teacher has requested that you provide feedback, by using the rubric on the
                        left-hand side of the video.
                      </p>
                      <p className={'mb-4'}>
                        Click on the rubric statements to tag where something has been done well (5) or where something
                        has not been done well (1). As this is a demonstration of the video feedback tool, any feedback
                        you add to the video will not be saved.
                      </p>
                      <p>
                        The video feedback tool can also be used to demonstrate good practice. For example, if this
                        teacher was really strong at using hinge questions, they could share a video of them
                        demonstrating good practice with their Great Teaching Team, to further facilitate those coaching
                        conversations and to develop colleagues’ adaptive expertise.
                      </p>
                    </>
                  ),
                }}
              />
            </div>
          )}
          {tagCommentModal}
          {shareVideoModal}
          {tagSetsModal}
          {editTitleModal}
          {deleteVideoModal}
          <div className={'field-mb'}>
            <Section size={'md'}>
              <div className={'mb-5 flex justify-between'}>
                <div>
                  <div>
                    {feedBackTags && isVideoOwner ? (
                      <Tooltip content={'Edit title'}>
                        <button
                          className={'rounded-lg p-2 hover:bg-gray-100'}
                          onClick={() => {
                            setEditTitleModalProps({ videoTitle: video.title });
                            toggleEditTitleModal(true);
                          }}
                        >
                          <h1>{editedTitle ?? video.title}</h1>
                        </button>
                      </Tooltip>
                    ) : (
                      <h1>{video.title}</h1>
                    )}
                    {feedBackTags && video.element && (
                      <span className={'align-baseline'}> - please give feedback on Element {video.element.value}</span>
                    )}
                  </div>
                  <span className={'text-muted'}>{video.created_by.name}</span>
                </div>
                {feedBackTags ? (
                  <div className={'flex justify-end items-center gap-5'}>
                    <Button
                      id={'feedbackSessionPage_cancelButton'}
                      height={65}
                      disabled={feedBackSessionMutation.isLoading || isFree}
                      onClick={() => {
                        cleanStates();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      id={'feedbackSessionPage_saveButton'}
                      height={65}
                      disabled={feedBackSessionMutation.isLoading || isFree}
                      onClick={() => {
                        feedBackSessionMutation.mutate({
                          tags: sessionTags.map((tag) => {
                            const tagCopy = { ...tag };
                            if (typeof tagCopy.id === 'string' && tagCopy.id.includes(TEMP_ID_PREFIX)) {
                              delete tagCopy.id;
                            }

                            return tagCopy;
                          }),
                          videoId: id,
                          tagSetId: tagSetId,
                          id: feedbackId,
                          title: editedTitle,
                        });
                      }}
                    >
                      {feedBackSessionMutation.isLoading ? 'Loading...' : 'Save'}
                    </Button>
                  </div>
                ) : (
                  <div className={'flex justify-end items-center gap-5'}>
                    {isVideoOwner && (
                      <>
                        <Button
                          id={'videoPlayerPage_deleteButton'}
                          height={65}
                          onClick={() => toggleDeleteVideoModal(true)}
                        >
                          Delete
                        </Button>
                        <Button
                          id={'videoPlayerPage_shareButton'}
                          height={65}
                          onClick={() => toggleShareVideoModal(true)}
                        >
                          Share
                        </Button>
                      </>
                    )}
                    <Button
                      id={'videoPlayerPage_newFeedbackSessionButton'}
                      height={65}
                      onClick={async () => {
                        if (!video.element) {
                          toggleTagSetsModal(true);
                          return;
                        }

                        const response = await getTagSet(video.element.tag_set_id);
                        setTagSetId(video.element.tag_set_id);
                        setFeedbackTags(response.data.data);
                      }}
                    >
                      New feedback session
                    </Button>
                  </div>
                )}
              </div>
              <div className={`flex justify-start mb-5`}>
                {feedBackTags && (
                  <div className={'bg-gray-100'}>
                    {feedBackTags.map((element: FeedbackElement) => {
                      const [elementTag, ...elementNameSplit] = element.name.split(' ');

                      return (
                        <Accordion
                          key={element.id}
                          title={elementNameSplit.join(' ')}
                          isOpen={isFree ? true : elementAccordion == element.id}
                          handleOpen={() => setElementAccordion(elementAccordion != element.id ? element.id : null)}
                          arrowPosition={'right'}
                          tag={elementTag}
                          style={{ fontWeight: 'bold' }}
                          tagStyle={{ background: element.colour }}
                        >
                          {element.tags.map((tag) => (
                            <div key={tag.id} className={'mb-2'}>
                              <div className={'mb-2'}>{tag.name}</div>
                              <div className={'flex justify-start gap-1 flex-wrap'}>
                                {['1', '2', '3', '4', '5'].map((icon) => (
                                  <TagIcon
                                    key={Math.random()}
                                    className={'cursor-pointer'}
                                    name={icon}
                                    colour={'gray'}
                                    onClick={() => addTagToFeedBackSession(element, tag, icon)}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </Accordion>
                      );
                    })}
                  </div>
                )}
                <div className={'relative w-full'}>
                  <video
                    controls
                    src={video.url}
                    className={'w-full'}
                    ref={videoRef}
                    onTimeUpdate={(e) => {
                      setCurrentVideoTime(Math.trunc(e.currentTarget.currentTime));
                    }}
                  />
                  {selectedTag && <TagOnVideo tag={selectedTag} onClose={() => setSelectedTag(null)} />}
                  {feedBackTags && (
                    <TagSet
                      tagSet={{ tags: sessionTags }}
                      key={Math.random()}
                      videoLength={videoRef.current?.duration ?? video.length}
                      videoElement={videoRef}
                      isSession
                      removeTag={(id: number | string) => {
                        setSessionTags((prevState) => prevState.filter((tag) => tag.id !== id));
                      }}
                      toggleCommentModal={(id: number | string, comment?: string) => {
                        setTagCommentModalProps({
                          tagId: id,
                          comment,
                          title: comment ? 'Edit comment' : 'Add comment',
                        });
                        toggleTagCommentModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </Section>
          </div>
          {video && (
            <div className={'field-mb'}>
              <Section size={null}>
                <Tab
                  items={!feedBackTags ? TABS : TABS.filter((tab) => tab.value !== FEEDBACK_TAB)}
                  active={activeTab}
                  clickOnTab={(value: number) => setActiveTab(value)}
                  parent={feedBackTags ? 'feedbackSessionPage' : 'videoPlayerPage'}
                >
                  <div className={`${classnames({ 'py-8 px-10': activeTab === COMMENTS_TAB })}`}>
                    <Tab.Content id={FEEDBACK_TAB} active={activeTab}>
                      <FeedbackTab
                        videoId={id}
                        videoLength={videoRef.current?.duration ?? video.length}
                        videoElement={videoRef}
                        selectTag={setSelectedTag}
                        currentVideoTime={currentVideoTime}
                        userId={userId}
                        onEdit={async (tagSet) => {
                          const response = await getTagSet(tagSet.tag_set!.id);
                          setFeedbackTags(response.data.data);
                          setTagSetId(tagSet.tag_set!.id);
                          setFeedbackId(tagSet.id);
                          setSessionTags(tagSet.tags);
                          document.getElementById('root')!.scrollTo(0, 0);
                        }}
                      />
                    </Tab.Content>
                    <Tab.Content id={COMMENTS_TAB} active={activeTab}>
                      <CommentsTab
                        parent={feedBackTags ? 'feedbackSessionPage' : 'videoPlayerPage'}
                        videoId={id}
                        userId={userId}
                        isFree={isFree}
                      />
                    </Tab.Content>
                  </div>
                </Tab>
              </Section>
            </div>
          )}
        </>
      ) : (
        <>loading...</>
      )}
    </DashboardLayout>
  );
};

export type FeedbackElement = {
  id: number;
  name: string;
  colour: string;
  tags: FeedbackTag[];
};

type FeedbackTag = {
  id: number;
  name: string;
};

export default VideoPlayerPage;
